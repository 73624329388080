import { Injectable } from '@angular/core';
@Injectable()
/**
 * <pre>
 *   Model component for Field List Row<br>
 * <br>
 *   Update Date         Update User         Contents Of Update<br> 
 *   2020/02/17          S.Sakamoto          Create New <br>
 * </pre>
 */
export class FieldRow {
    direct_id: string;
    data_version: string;
    inspection_date: string;

    detail_link: boolean;
    field_no: string;
    field_id: string;
    shooting_result: string;
    request_date: string;
    analysis_result: string;
    analysis_result_display: string;

    inspection_user_id: string;
    inspection_result: string;
    inspection_result_display: string;
    inspection_error_code: string;
    inspection_error_code_display: string;
    inspection_other_error_comment: string;
    modify_flag: string;
    delivery_user_id: string;
    delivery_error_flag: string;
    delivery_date: string;

    inspection_biko: string;


}