import { Component, OnInit } from '@angular/core';
import { CommonConst } from '../../constant/common-const';
import { MessageConst } from '../../constant/message-const';
import { Router } from '@angular/router';
import { LoginService } from '../../service/login.service';
import { MessageDialogComponent } from '../../dialog/message-dialog/message-dialog.component';
import { MatDialog} from '@angular/material/dialog';

export interface DialogData {
    dialogMessage: string;
}

@Component({
    selector: 'app-changepass',
    templateUrl: './changepass.component.html',
    styleUrls: ['./changepass.component.css']
})
export class ChangepassComponent implements OnInit {
    userIdInput:string;
    oldPasswordInput:string;
    newPasswordInput:string;
    newPasswordReInput:string;
    messageList: string[];
    dialogRef;

    title:string;
    password_setting:string;
    login_id:string;
    confirm_code:string;
    new_password:string;
    confirm_password:string;
    setting_btn:string

    constructor(
        private router: Router, 
        private loginservice: LoginService, 
        public dialog: MatDialog
    ) {
        console.log("ChangepassComponent----------------constructor() --------------");
        this.title = CommonConst.TITLE;
        this.password_setting = CommonConst.PASSWORD_SETTING
        this.login_id = CommonConst.LOGIN_ID;
        this.confirm_code = CommonConst.CONFIRM_CODE;
        this.new_password = CommonConst.NEW_PASSWORD;
        this.confirm_password = CommonConst.CONFIRM_PASSWORD;
        this.setting_btn = CommonConst.SETTING_BTN;
    }

    ngOnInit() {
        console.log("ChangepassComponent----------------ngOnInit() --------------");
        // initial form value
        this.userIdInput = "";
        this.oldPasswordInput = "";
        this.newPasswordInput = "";
        this.newPasswordReInput = "";
        this.messageList = [""];
    }

    changePassword() {
        console.log("ChangepassComponent----------------changePassword() --------------");
        this.messageList = [""];
        // input check
        if(this.userIdInput == "") {
            this.messageList.push(MessageConst.LOGIN_NO_LOGINID);
        }
        if(this.oldPasswordInput == "") {
            this.messageList.push(MessageConst.CHANGEPASS_NO_COMFIRM_KEY);
        }
        if(this.newPasswordInput == "" && this.newPasswordReInput == "") {
            this.messageList.push(MessageConst.CHANGEPASS_NO_NEWPASSWORD);
        } else {
            if(this.newPasswordInput == "") {
                this.messageList.push(MessageConst.CHANGEPASS_NO_NEWPASSWORD);
            }
            if(this.newPasswordReInput == "") {
                this.messageList.push(MessageConst.CHANGEPASS_NO_REINPUTNEWPASSWORD);
            }          
        }
        if(this.messageList.length > 1) {
            return;
        }

        if (this.newPasswordInput.length < 8 || !this.newPasswordInput.match(/^[a-zA-Z0-9 -/:-@\[-\`\{-\~]+$/)) {
            this.messageList.push(MessageConst.CHANGEPASS_INVALIDFORMAT_NEWPASSWORD);
        }
        if(this.messageList.length > 1) {
            return;
        }
        if (this.newPasswordInput != this.newPasswordReInput) {
            this.messageList.push(MessageConst.CHANGEPASS_UNMATCHFORMAT_NEWPASSWORD);
        }
        if(this.messageList.length > 1) {
           return;
        }

        try {
            // change password
            this.loginservice.changePassword(this.userIdInput, String(this.oldPasswordInput), this.newPasswordInput)
            .then((result) => {
                console.log("success change password!")
                this.dialogRef = this.dialog.open(MessageDialogComponent,{
                    data: {dialogMessage: MessageConst.CHANGEPASS_OK}
                });
                this.dialogRef.afterClosed().subscribe(result => {
                    console.log(result);
                    if(result == true){
                        this.router.navigate(['login']);
                    }
                });
            }).catch((err) => {
                this.messageList.push(err.additionalMessage.message);
                console.log("System cognito setting" + err);
            });
        } catch(e) {
            console.log("System " + e);
            this.messageList.push(MessageConst.SYSTEM_ERROR_MESSAGE);
        }

    }
}
