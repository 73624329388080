import { Component, OnInit } from '@angular/core';
import { CommonConst } from '../../constant/common-const';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    ui_system_version: string;
    year:number;
    constructor() {
        console.log("[[FooterComponent]]------------------constructor() ------------");
        this.ui_system_version = CommonConst.UI_SYSTEM_VERSION;
    }
    ngOnInit() {
        console.log("[[FooterComponent]]------------------ngOnInit() ------------");
        this.year = new Date().getFullYear();
    }
}
