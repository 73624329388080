 /**
 * <pre>
 *   Model component for Direct ID Search Condition<br>
 * <br>
 *   Update Date         Update User         Contents Of Update<br> 
 *   2020/02/17          S.Sakamoto          Create New <br>
 * </pre>
 */
export class DirectCondition {

    direct_id: string;
    scheduled_date_from: string;
    scheduled_date_to: string;
    latest_request_date_from: string;
    latest_request_date_to: string;
    direct_user_id: string;

    work_kind_normal: boolean;
    work_kind_marker: boolean;
    work_kind_csv: boolean;
    work_kind_test: boolean;
    work_kind_cancel: boolean;

    option: boolean;
}