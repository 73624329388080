import { Component, OnInit } from '@angular/core';
import { CommonConst } from '../../constant/common-const';

@Component({
    selector: 'app-infomation-dialog',
    templateUrl: './infomation-dialog.component.html',
    styleUrls: ['./infomation-dialog.component.css']
})
export class InfomationDialogComponent implements OnInit {
    discard_message_1: string;
    discard_message_2: string;
    discard_message_3: string;

    constructor() {
        this.discard_message_1 = CommonConst.DISCARD_MESSAGE_1;
        this.discard_message_2 = CommonConst.DISCARD_MESSAGE_2;
        this.discard_message_3 = CommonConst.DISCARD_MESSAGE_3;
     }

    ngOnInit() {
    }

}
