import { MatPaginator, MatPaginatorIntl, throwMatDialogContentAlreadyAttachedError } from '@angular/material';

export class CustomPaginator extends MatPaginatorIntl {
  constructor() {
    super();
//    this.nextPageLabel = ' My new label for next page';
//    this.previousPageLabel = ' My new label for previous page';
//    this.itemsPerPageLabel = 'Task per screen';
  }
  getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '0 od ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
   //If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' od ' + length;
  };
}