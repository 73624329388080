import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonConst } from '../../constant/common-const';
import { MessageConst } from '../../constant/message-const';
import { LoginService } from '../../service/login.service';
import { ExecuteResult } from '../../model/execute-result';
import { CognitoService } from '../../service/cognito.service';
import { ResetpassDialogComponent } from '../../dialog/resetpass-dialog/resetpass-dialog.component';
import { MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginId: string;
    passWord: string;
    messageList: string[];
    dialogRef;

    title: string;
    login_id: string;
    password: string;
    login_btn: string;
    passwordlost: string;

    private confirmLoginResult: ExecuteResult;
    private loginResult:        ExecuteResult;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private loginservice: LoginService, private cognito: CognitoService, public dialog: MatDialog) {
        console.log("LoginComponent------------constructor() -------------");
        this.title = CommonConst.TITLE;
        this.login_id = CommonConst.LOGIN_ID;
        this.password = CommonConst.PASSWORD
        this.login_btn = CommonConst.LOGIN_BTN;
        this.passwordlost = CommonConst.PASSWORDLOST;

        this.cognito.isAuthenticated()
        .then((res) => {
            this.router.navigate(['direct-list']);
        })
        .catch((err) => {
            console.log(err);
            this.router.navigate(['login']);
        });
    }

    ngOnInit() {
        console.log("LoginComponent------------ngOnInit() -------------");
        // initial form value
        this.loginId = "";
        this.passWord = "";
        this.messageList = [""];

        try {
            // confirm login
            this.confirmLoginResult = this.loginservice.confirmLogin();
            if(this.confirmLoginResult.result){
                // Transition to direct-list screen
                this.router.navigate(['direct-list']);  
            }
        } catch(e) {
            console.log("System " + e);
            this.messageList.push(MessageConst.SYSTEM_ERROR_MESSAGE);
        }
    }

    login(){
        console.log("LoginComponent------------login() -------------");
        this.messageList = [""];
        try {
            // input check
            if(this.loginId ==""){
                this.messageList.push(MessageConst.LOGIN_NO_LOGINID);
            }
            if(this.passWord ==""){
                this.messageList.push(MessageConst.LOGIN_NO_PASSWORD);
            }
            if(this.messageList.length > 1) {
                return;
            }

            this.loginservice.login(this.loginId,this.passWord)
            .then((result) => {
                this.router.navigate(['direct-list']);  
            }).catch((err) => {
                this.messageList.push(err.additionalMessage.message);
            });
        
        } catch(e) {
            console.log("System " + e);
            this.messageList.push(MessageConst.SYSTEM_ERROR_MESSAGE);
        }      
    }

    openResetPassDialog():void {
        console.log("LoginComponent------------openResetPassDialog() -------------");
        try {
            this.dialogRef = this.dialog.open(ResetpassDialogComponent,{
                data: {dialogMessage: ""}
            });
            this.dialogRef.afterClosed().subscribe(result => {
                console.log("LoginComponent------------resetPassDialog afterClosed() -------------");
            });
        } catch(e) {
            console.log("System " + e);
            this.messageList.push(MessageConst.SYSTEM_ERROR_MESSAGE);
        }      
    }

}
