import { Injectable } from '@angular/core';
import { ExecuteResult } from '../model/execute-result';
import { MessageConst } from '../constant/message-const';
import { Subject } from 'rxjs';
import { CognitoService } from '../service/cognito.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    private executeResult: ExecuteResult;
    private username = new Subject<string>();
    public username$ = this.username.asObservable();
    private role = new Subject<string>();
    public role$ = this.role.asObservable();

    constructor(private cognito: CognitoService) {
        console.log("<<LoginService>>------------constructor()-----------");
    }

    confirmLogin():ExecuteResult {
        console.log("<<LoginService>>------------confirmLogin()-----------");
        this.executeResult = {
            resultArray: null,
            resultDataSorce: null,
            totalCount: null,
            result:false,
            message:"comfirm NG"
        };
        return this.executeResult;
    }
    
    login(loginId:string,passWord:string): Promise<any> {
        console.log("<<LoginService>>------------login()-----------");
        return new Promise((resolve, reject) => {
            this.cognito.login(loginId, passWord)
            .then((result) => {
                this.executeResult = {
                    resultArray: null,
                    resultDataSorce: null,
                    totalCount: null,
                    result:true,
                    message:"login success"
                }; 
                this.username.next(result["idToken"]["payload"]["cognito:username"]);
                this.role.next(result["idToken"]["payload"]["custom:role"]);
                resolve(this.executeResult);
            }).catch((err) => {
                err.additionalMessage = {
                    resultArray: null,
                    totalCount: null,
                    result:false,
                    message:MessageConst.LOGIN_ERROR
                }
                reject(err);
            });
        });
    }

    logout(): Promise<any> {
        console.log("<<LoginService>>------------logout()-----------");
        return new Promise((resolve, reject) => {
            this.cognito.logout()
            .then((result) => {
            this.executeResult = {
                resultArray: null,
                resultDataSorce: null,
                totalCount: null,
                result:true,
                message:"logout success"
            };
            resolve(this.executeResult);
            }).catch((err) => {
                err.additionalMessage = {
                    resultArray: null,
                    totalCount: null,
                    result:false,
                    message:"logout error"
                }
                reject(err);
            });
        });
    }

    changePassword(userIdInput: string, oldPasswordInput: string, newPasswordInput: string): Promise<any> {
        console.log("<<LoginService>>------------changePassword()-----------");
        return new Promise((resolve, reject) => {
            this.cognito.changePassword(userIdInput, newPasswordInput, oldPasswordInput)
            .then((result) => {
                this.executeResult = {
                    resultArray: null,
                    resultDataSorce: null,
                    totalCount: null,
                    result:true,
                    message:"setPassword success"
                };
                resolve(this.executeResult);
            }).catch((err) => {
                if ( err.code == "InvalidPasswordException" ) {
                    err.additionalMessage = {
                        resultArray: null,
                        totalCount: null,
                        result:false,
                        message:MessageConst.CHANGEPASS_INVALIDFORMAT_NEWPASSWORD
                    }
                } else if ( err.code == "ExpiredCodeException" ) {
                    err.additionalMessage = {
                        resultArray: null,
                        totalCount: null,
                        result:false,
                        message:MessageConst.CHANGEPASS_EXPIRED_COMFIRM_KEY
                    }
                } else if ( err.code == "CodeMismatchException" ) {
                    err.additionalMessage = {
                        resultArray: null,
                        totalCount: null,
                        result:false,
                        message:MessageConst.CHANGEPASS_INVALID_KEY
                    }
                } else {
                    err.additionalMessage = {
                        resultArray: null,
                        totalCount: null,
                        result:false,
                        message:MessageConst.CHANGEPASS_NG
                    }
                }
                reject(err);
            });
        });
    }

    sendPasswordReset(loginId:string): Promise<any> {
        console.log("<<LoginService>>------------sendPasswordReset()-----------");
        return new Promise((resolve, reject) => {
            this.cognito.resetPassword(loginId)
            .then((result) => {
                this.executeResult = {
                    resultArray: null,
                    resultDataSorce: null,
                    totalCount: null,
                    result:true,
                    message:MessageConst.RESET_PASS_SUCCESS
                };  
                console.log("<<LoginService>>------------sendPasswordReset()then");
                resolve(this.executeResult);
            }).catch((err) => {
                err.additionalMessage = {
                    resultArray: null,
                    totalCount: null,
                    result:false,
                    message:MessageConst.RESET_PASS_ERROR
                };        
                reject(err);
            });
        });
    }

}
