import { Component, OnInit,Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
    dialogMessage: string;
}

@Component({
    selector: 'app-running-dialog',
    templateUrl: './running-dialog.component.html',
    styleUrls: ['./running-dialog.component.css']
})

export class RunningDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<RunningDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

    closeDialog() {
        this.dialogRef.close('');
    }

    ngOnInit() {
    }

}
