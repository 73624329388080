import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule, JsonpModule } from '@angular/http';
import { DatePipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { ChangepassComponent } from './component/changepass/changepass.component';
import { DirectListComponent } from './component/direct-list/direct-list.component';
import { FieldListComponent } from './component/field-list/field-list.component';
import { InspectionComponent } from './component/inspection/inspection.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'hammerjs';
import { MatButtonModule,MatCheckboxModule,MatRadioModule,MatCardModule,MatInputModule} from '@angular/material';
import { MatTableModule} from '@angular/material/table'; 
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatGridListModule} from '@angular/material/grid-list';
import { MatSelectModule} from '@angular/material/select';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { DateAdapter,MAT_DATE_LOCALE,MatNativeDateModule } from '@angular/material/';
import { ScrollDispatchModule} from '@angular/cdk/scrolling'; 
import { MatSortModule} from '@angular/material/sort';
import { MatPaginatorIntl} from '@angular/material';
import { CustomPaginator} from './mat-settings/custom-paginator';
import { MatDialog,MatDialogModule} from '@angular/material/dialog';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner'; 
import { MatIconModule} from '@angular/material/icon'; 
import { MatProgressBarModule } from '@angular/material/progress-bar'; 

import { FieldRow } from './model/field-row';
import { RunningDialogComponent } from './dialog/running-dialog/running-dialog.component';
import { ProgressDialogComponent } from './dialog/progress-dialog/progress-dialog.component';
import { ConfirmDialogComponent } from './dialog/confirm-dialog/confirm-dialog.component';
import { InfomationDialogComponent } from './dialog/infomation-dialog/infomation-dialog.component';

import { LoginService} from './service/login.service';
import { CognitoService} from './service/cognito.service';
import { DbdataService} from './service/dbdata.service';
import { ResetpassDialogComponent } from './dialog/resetpass-dialog/resetpass-dialog.component';
import { MessageDialogComponent } from './dialog/message-dialog/message-dialog.component';
import { LogoutDialogComponent } from './dialog/logout-dialog/logout-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    ChangepassComponent,
    DirectListComponent,
    FieldListComponent,
    InspectionComponent,
    RunningDialogComponent,
    ProgressDialogComponent,
    ConfirmDialogComponent,
    InfomationDialogComponent,
    ResetpassDialogComponent,
    MessageDialogComponent,
    LogoutDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatCardModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatGridListModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ScrollDispatchModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatProgressBarModule,
    HttpModule,
    JsonpModule
  ],
  entryComponents: [
    RunningDialogComponent,
    ProgressDialogComponent,
    ConfirmDialogComponent,
    InfomationDialogComponent,
    ResetpassDialogComponent,
    MessageDialogComponent,
    LogoutDialogComponent

  ],

  providers: [
    {
      provide: MAT_DATE_LOCALE, 
      useValue: 'ja-JP'
    },
  
    {
      provide: MatPaginatorIntl, 
      useClass: CustomPaginator
    },
    FieldRow,
    CognitoService,
    LoginService,
    DbdataService,
    DatePipe
//    MatDialogRef
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
