import { Component, OnInit,Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
    dialogMessage: string;
    currentNo: string;
    totalNo: string;
    btnCtl: string;
    message_style: string;
}

@Component({
    selector: 'app-progress-dialog',
    templateUrl: './progress-dialog.component.html',
    styleUrls: ['./progress-dialog.component.css']
})
export class ProgressDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ProgressDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { 
        console.log("ProgressDialogComponent------------constructor() -------------");
    }

    ngOnInit() {
        console.log("ProgressDialogComponent------------ngOnInit() -------------");
    }

}
