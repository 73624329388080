import { Injectable } from '@angular/core';
import { Headers, Http, Jsonp, RequestOptions, URLSearchParams} from '@angular/http';
import { DatePipe } from '@angular/common';
import "rxjs";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { CommonConst } from '../constant/common-const';
import { ExecuteResult } from '../model/execute-result';
import { DirectCondition } from '../model/direct-condtion';
import { DirectRow } from '../model/direct-row';
import { FieldRow } from '../model/field-row';
import { MatTableDataSource} from '@angular/material/table';
import { DirectUser } from '../model/direct-user';
import { environment } from './../../environments/environment';

/**
 * <pre>
 *   Database Table Provide the ability to managebr>
 * <br>
 *   Update Date         Update User         Contents Of Update<br> 
 *   2020/02/17          S.Sakamoto          Create New<br>
 * </pre>
 */
@Injectable({
    providedIn: 'root'
})
export class DbdataService {

    executeResult: ExecuteResult;
    elementData: DirectRow[];
    directUserIds: DirectUser[];
    fieldElementData: FieldRow[];

    constructor(private http: Http, private jsonp: Jsonp, public datePipe: DatePipe) {
        console.log("<<DbdataService>>---------constructor() --------------");
    }

    escapeString( str : string): string{
        return str
            .replace(/\\/g, "\\\\")
            .replace(/'/g, "''")
            .replace(/\"/g, '\\"');
    }

    getUsers(): Promise<any> {
        console.log("<<DbdataService>>---------getUsers() --------------");
        let headers = new Headers({ 'x-api-key': environment.x_api_key });
        let options = new RequestOptions({ headers: headers });

        return new Promise((resolve, reject) => { 
            this.http.get(environment.api_url + "/users",options).subscribe(
                response => {
                    console.log("<<DbdataService>>---------getUsers() response");
                    console.log(response.text());
                    let jsonResult = JSON.parse(response.text());
                    console.log("statusCode = " + jsonResult.statusCode);
                    this.directUserIds = [];
                    let directUser: DirectUser;
                    directUser = {
                        direct_user_id: ""
                    }
                    this.directUserIds[0]= directUser;
                    for (var i = 0; i < jsonResult.results.length; i++) {
                        directUser = {
                            direct_user_id: jsonResult.results[i]
                        }
                        this.directUserIds[i+1]= directUser;
                    }

                    this.executeResult = {
                        resultArray: this.directUserIds,
                        resultDataSorce: null,
                        totalCount: null,
                        result: true,
                        message: jsonResult.messages
                    };
                    resolve(this.executeResult);                    
                },
                error => {
                    console.log("<<DbdataService>>---------getUsers() error");
                    // URL, x_api_key error
                    console.log(error);
                    reject(error);
                }
            );
        });
    }

    getDirectList(directCondition: DirectCondition): Promise<any> {
        console.log("<<DbdataService>>---------getDirectList() --------------");

        let work_kind_normal = "0";
        let work_kind_marker = "0";
        let work_kind_csv = "0";
        let work_kind_test = "0";
        let work_kind_cancel = "0";
        let option = "0";
        if(directCondition.work_kind_normal) { work_kind_normal = "1"; }
        if(directCondition.work_kind_marker) { work_kind_marker = "1"; }
        if(directCondition.work_kind_csv) { work_kind_csv = "1"; }
        if(directCondition.work_kind_test) { work_kind_test = "1"; }
        if(directCondition.work_kind_cancel) { work_kind_cancel = "1"; }
        if(directCondition.option) { option = "1"; }

        let scheduled_date_from;
        let scheduled_date_to;
        let latest_request_date_from;
        let latest_request_date_to;
        if(directCondition.scheduled_date_from){
            // change jst to utc
            let sDateFrom =new Date(directCondition.scheduled_date_from);
            sDateFrom.setDate(sDateFrom.getDate() - 1);
            scheduled_date_from = this.datePipe.transform(sDateFrom, "yyyy/MM/dd 15:00:00");
        }
        if(directCondition.scheduled_date_to){
            // change jst to utc
            let sDateTo =new Date(directCondition.scheduled_date_to);
            scheduled_date_to = this.datePipe.transform(sDateTo, "yyyy/MM/dd 14:59:59");
        }
        if(directCondition.latest_request_date_from){
            latest_request_date_from = this.datePipe.transform(new Date(directCondition.latest_request_date_from), "yyyy/MM/dd");    
        }
        if(directCondition.latest_request_date_to){
            latest_request_date_to = this.datePipe.transform(new Date(directCondition.latest_request_date_to), "yyyy/MM/dd 23:59:59");    
        }

        console.log("param direct_id                = " + directCondition.direct_id);
        console.log("param scheduled_date_from      = " + scheduled_date_from);
        console.log("param scheduled_date_to        = " + scheduled_date_to);
        console.log("param latest_request_date_from = " + latest_request_date_from);
        console.log("param latest_request_date_to   = " + latest_request_date_to);
        console.log("param direct_user_id    = " + directCondition.direct_user_id);
        console.log("param work_kind_nomal   = " + work_kind_normal);
        console.log("param work_kind_maker   = " + work_kind_marker);
        console.log("param work_kind_csv     = " + work_kind_csv);
        console.log("param work_kind_test    = " + work_kind_test);
        console.log("param work_kind_cancel  = " + work_kind_cancel);
        console.log("param option = " + option);

        let headers = new Headers({ 'x-api-key': environment.x_api_key });

        let apiParams = new URLSearchParams();
        apiParams.append('direct_id', directCondition.direct_id);
        apiParams.append('scheduled_date_from', scheduled_date_from);
        apiParams.append('scheduled_date_to', scheduled_date_to);
        apiParams.append('latest_request_date_from', latest_request_date_from);
        apiParams.append('latest_request_date_to', latest_request_date_to);
        apiParams.append('direct_user_id', directCondition.direct_user_id);
        apiParams.append('work_kind_normal', work_kind_normal);
        apiParams.append('work_kind_marker', work_kind_marker);
        apiParams.append('work_kind_csv', work_kind_csv);
        apiParams.append('work_kind_test', work_kind_test);
        apiParams.append('work_kind_cancel', work_kind_cancel);
        apiParams.append('option', option);

        let options = new RequestOptions({ headers: headers, params: apiParams });
        console.log("environment.api_url = " + environment.api_url);
        console.log("environment.x_api_key = " + environment.x_api_key);

        return new Promise((resolve, reject) => { 
            this.http.get(environment.api_url + "/directs/list",options).subscribe(
                response => {
                    console.log("<<DbdataService>>---------getDirectList() response");
                    let jsonResult = JSON.parse(response.text());
                    console.log("statusCode = " + jsonResult.statusCode);
                    console.log("numOfElements = " + jsonResult.numOfElements);
                    this.elementData = [];
                    let count = 0;
                    
                    if (jsonResult.numOfElements != "0") {
                        let nowDateTime = new Date().getTime();

                        for(let jsonDirectList of jsonResult.results) {
                            let directRow: DirectRow;
                            let warningRowFlag: boolean = false;
                            let direct_comment = "-";

                            if (jsonDirectList.contract_kind == null || jsonDirectList.contract_kind == "") {
                                jsonDirectList.contract_kind = "-";
                            }
                            if (jsonDirectList.scheduled_date == null || jsonDirectList.scheduled_date == "") {
                                jsonDirectList.scheduled_date = "-";
                            } else {
                                var date = new Date(jsonDirectList.scheduled_date);

                                // change utc to jst
                                date.setHours(date.getHours() + 9);
                                var yyyy = date.getFullYear();

                                var toDoubleDigits = function(num) {
                                    num += "";
                                    if (num.length === 1) {
                                      num = "0" + num;
                                    }
                                   return num;
                                }

                                var mm = toDoubleDigits(date.getMonth() + 1);
                                var dd = toDoubleDigits(date.getDate());
                                var hh = toDoubleDigits(date.getHours());
                                var mi = toDoubleDigits(date.getMinutes());
                                var se = toDoubleDigits(date.getSeconds());
                                jsonDirectList.scheduled_date = yyyy + "-" + mm + "-" + dd + " " + hh + ":" +mi + ":" + se;
                            }
                            if (jsonDirectList.prefecture_name == null || jsonDirectList.prefecture_name == "") {
                                jsonDirectList.prefecture_name = "-";
                            }
                            if (jsonDirectList.camera_kind == null || jsonDirectList.camera_kind == "") {
                                jsonDirectList.camera_kind = "-";
                            }
                            if (jsonDirectList.work_kind == null || jsonDirectList.work_kind == "") {
                                jsonDirectList.work_kind = "-";
                            }
                            if (jsonDirectList.analysis_error_number_of_field == null) {
                                jsonDirectList.analysis_error_number_of_field = "0";
                            }
                            if (jsonDirectList.non_inspection_number_of_field == null) {
                                jsonDirectList.non_inspection_number_of_field = "0";
                            }
                            if (jsonDirectList.pending_inspection_number_of_field == null) {
                                jsonDirectList.pending_inspection_number_of_field = "0";
                            }
                            if (jsonDirectList.non_delivery_number_of_field == null) {
                                jsonDirectList.non_delivery_number_of_field = "0";
                            }
                            if (jsonDirectList.delivery_number_of_field == null) {
                                jsonDirectList.delivery_number_of_field = "0";
                            }
                            if (jsonDirectList.request_number_of_field == null) {
                                jsonDirectList.request_number_of_field = "0";
                            }
                            if (jsonDirectList.shooting_number_of_field == null) {
                                jsonDirectList.shooting_number_of_field = "0";
                            }

                            if (jsonDirectList.direct_comment != null && jsonDirectList.direct_comment != "") {
                                direct_comment = jsonDirectList.direct_comment;
                            }

                            if (jsonDirectList.latest_request_date == null) {
                                jsonDirectList.latest_request_date = "9999/12/31 23:59"
                            } else {

                                if(jsonDirectList.non_inspection_number_of_field != 0 || 
                                   jsonDirectList.non_delivery_number_of_field   != 0 ||
                                   jsonDirectList.analysis_error_number_of_field != 0 
                                ) {    
        
                                    let year   = parseInt(jsonDirectList.latest_request_date.substr(0,4));
                                    let month  = parseInt(jsonDirectList.latest_request_date.substr(5,2));
                                    month  = month -1;
                                    let day    = parseInt(jsonDirectList.latest_request_date.substr(8,2));
                                    let hour   = parseInt(jsonDirectList.latest_request_date.substr(11,2));
                                    let minute = parseInt(jsonDirectList.latest_request_date.substr(14,2));
                                    let second = parseInt(jsonDirectList.latest_request_date.substr(17,2));
                                    let requestDate = new Date(year,month,day,hour,minute,second);
                                    
                                    let elapsedTime = nowDateTime - requestDate.getTime();
                                    let expireMilliSecond = CommonConst.HOUR_MILLISECOND * CommonConst.WARNING_HOUR_AFTER_RECEPTION;
                                    if(expireMilliSecond < elapsedTime) {
                                        warningRowFlag = true;
                                    }                            
                                }
                                jsonDirectList.scheduled_date = jsonDirectList.scheduled_date.substr(0,10);
                                jsonDirectList.latest_request_date = jsonDirectList.latest_request_date.substr(0,16);

                            }
                            directRow = {
                                no: count,
                                direct_id: jsonDirectList.direct_id,
                                contract_kind: jsonDirectList.contract_kind,
                                scheduled_date: jsonDirectList.scheduled_date,
                                prefecture_name: jsonDirectList.prefecture_name,
                                camera_kind: jsonDirectList.camera_kind,
                                latest_request_date: jsonDirectList.latest_request_date,
                                work_kind: jsonDirectList.work_kind,
                                direct_user_id: jsonDirectList.direct_user_id,
                                request_number_of_field: jsonDirectList.request_number_of_field,
                                shooting_number_of_field: jsonDirectList.shooting_number_of_field,
                                analysis_error_number_of_field: jsonDirectList.analysis_error_number_of_field,
                                non_inspection_number_of_field: jsonDirectList.non_inspection_number_of_field,
                                pending_inspection_number_of_field: jsonDirectList.pending_inspection_number_of_field,
                                non_delivery_number_of_field: jsonDirectList.non_delivery_number_of_field,
                                delivery_number_of_field: jsonDirectList.delivery_number_of_field,
                                direct_comment: direct_comment,
                                warningRowFlag: warningRowFlag
                            }
                            this.elementData.push(directRow);
                            count++;
                        }
                    }

                    this.executeResult = {
                        resultArray: null,
                        resultDataSorce: new MatTableDataSource<DirectRow>(this.elementData),
                        totalCount: jsonResult.numOfElements,
                        result: true,
                        message: "API Response OK"
                    };  
                    resolve(this.executeResult);
                },
                error => {
                    console.log("<<DbdataService>>---------getDirectList() error");
                    console.log(error)
                    reject(error);
                }
            );
        });
    }

    putDirectUser(inparam_direct_id: string, inparam_direct_user_id: string, username: string): Promise<any> {
        console.log("<<DbdataService>>---------putDirectUser() --------------");
        if( typeof inparam_direct_user_id === 'undefined' || inparam_direct_user_id == ""){
            inparam_direct_user_id = null;
        }
        let headers = new Headers({ 'x-api-key': environment.x_api_key });
        let options = new RequestOptions({ headers: headers});
        let body: any = {
            direct_id: inparam_direct_id,
            update_user_id: username,
            updates: {direct_user_id: inparam_direct_user_id}
        };
        return new Promise((resolve, reject) => { 
            this.http.put(environment.api_url + "/directs", body, options).subscribe(
                response => {
                    console.log("<<DbdataService>>---------putDirectUser() response");
                    console.log(response.text());
                    let jsonResult = JSON.parse(response.text());
                    console.log("statusCode = " + jsonResult.statusCode);
                    this.executeResult = {
                        resultArray: null,
                        resultDataSorce: null,
                        totalCount: null,
                        result: true,
                        message: "API Response success"
                    };  
                    resolve(this.executeResult);
                },
                error => {
                    console.log("<<DbdataService>>---------putDirectUser() error");
                    console.log(error)
                    reject(error);
                }
            );
        });
    }

    putDirectComment(inparam_direct_id: string, inparam_direct_comment: string, username: string): Promise<any> {
        console.log("<<DbdataService>>---------putDirectComment() --------------");
        let headers = new Headers({ 'x-api-key': environment.x_api_key });
        let options = new RequestOptions({ headers: headers});
        let direct_comment = null;
        if (inparam_direct_comment != null) {
            direct_comment = this.escapeString(inparam_direct_comment);
        }
        let body: any = {
            direct_id: inparam_direct_id,
            update_user_id: username,
            updates: {direct_comment}
        };

        return new Promise((resolve, reject) => { 
            this.http.put(environment.api_url + "/directs", body, options).subscribe(
                response => {
                    console.log("<<DbdataService>>---------putDirectComment() response");
                    console.log(response.text());
                    let jsonResult = JSON.parse(response.text());
                    console.log("statusCode = " + jsonResult.statusCode);
                    this.executeResult = {
                        resultArray: null,
                        resultDataSorce: null,
                        totalCount: null,
                        result: true,
                        message: "API Response success"
                    };  
                    resolve(this.executeResult);
                },
                error => {
                    console.log("<<DbdataService>>---------putDirectComment() error");
                    console.log(error)
                    reject(error);
                }
            );
        });
    }

    getFieldList(direct_id: string): Promise<any> {
        console.log("<<DbdataService>>---------getFieldList() --------------");
        let headers = new Headers({ 'x-api-key': environment.x_api_key });
        let apiParams = new URLSearchParams();
        apiParams.append('direct_id', direct_id);
        let options = new RequestOptions({ headers: headers, params: apiParams });
        return new Promise((resolve, reject) => { 
            this.http.get(environment.api_url + "/fields",options).subscribe(
                response => {
                    console.log("<<DbdataService>>---------getFieldList() response--------------");
                    console.log(response);
                    let jsonResult = JSON.parse(response.text());
                    console.log("statusCode = " + jsonResult.statusCode);
                    console.log("numOfElements = " + jsonResult.numOfElements);
                    this.fieldElementData = [];
                    
                    if (jsonResult.numOfElements != "0") {
                        for(let jsonFieldList of jsonResult.results) {
                            let fieldRow: FieldRow;
                            let detail_link = false;
                            if (jsonFieldList.analysis_result == "1"){
                                detail_link = true;
                            }

                            if (jsonFieldList.field_no == null) {
                                 jsonFieldList.field_no = "-"; 
                            }
                            if (jsonFieldList.shooting_result == null || jsonFieldList.shooting_result == "") { 
                                jsonFieldList.shooting_result = "-"; 
                            }
                            if (jsonFieldList.request_date == null) { 
                                jsonFieldList.request_date = "-"; 
                            } else {
                                jsonFieldList.request_date = jsonFieldList.request_date.substr(0,16);
                            }

                            let analysis_result: string;
                            let analysis_result_display: string;

                            if(jsonFieldList.analysis_result == null) {
                                jsonFieldList.analysis_result = "";
                            }
                            analysis_result = jsonFieldList.analysis_result;
                            for (let analysisResult of CommonConst.ANALYSIS_RESULT_LIST) {
                                if (analysisResult["key"] == jsonFieldList.analysis_result) {
                                    analysis_result_display = analysisResult["value"];
                                    break;
                                }
                            }

                            let modify_flag: string;
                            if(jsonFieldList.modify_flag == null || jsonFieldList.modify_flag == "") {
                                jsonFieldList.modify_flag = "0";
                            }
                            for (let modifyFlag of CommonConst.MODIFY_FLAG_LIST) {
                                if (modifyFlag["key"] == jsonFieldList.modify_flag) {
                                    modify_flag = modifyFlag["value"];
                                    break;
                                }
                            }
        
                            let inspection_error_code: string = jsonFieldList.inspection_error_code;
                            let inspection_error_code_display: string = "-";
                            for (let errorCode of CommonConst.ERROR_CODE_LIST) {
                                if (errorCode["key"] == jsonFieldList.inspection_error_code) {
                                    if (jsonFieldList.inspection_error_code == "") { break; }
                                    inspection_error_code_display = jsonFieldList.inspection_error_code + ":" + errorCode["value"];
                                    break;
                                }
                            }
        
                            let inspection_result: string = jsonFieldList.inspection_result;
                            let inspection_result_display: string = "-";
                            for (let inspectionResult of CommonConst.INSPECTION_RESULT_LIST) {
                                if (inspectionResult["key"] == jsonFieldList.inspection_result) {
                                    inspection_result_display = inspectionResult["value"];
                                    break;
                                }
                            }
                            if (jsonFieldList.inspection_user_id == null || jsonFieldList.inspection_user_id == "") { 
                                jsonFieldList.inspection_user_id = "-"; 
                            }
                            if (jsonFieldList.inspection_other_error_comment == null || jsonFieldList.inspection_other_error_comment == "") { 
                                jsonFieldList.inspection_other_error_comment = "-"; 
                            }
                            if (jsonFieldList.delivery_user_id == null || jsonFieldList.delivery_user_id == "") { 
                                jsonFieldList.delivery_user_id = "-"; 
                            }
                            if (jsonFieldList.delivery_error_flag == null || jsonFieldList.delivery_error_flag == "") { 
                                jsonFieldList.delivery_error_flag = "-"; 
                            }
                            if (jsonFieldList.delivery_date == null) { 
                                jsonFieldList.delivery_date = "-"; 
                            } else {
                                jsonFieldList.delivery_date = jsonFieldList.delivery_date.substr(0,16);
                            }

                            if (jsonFieldList.inspection_biko == null || jsonFieldList.inspection_biko == "") { 
                                jsonFieldList.inspection_biko = "-"; 
                            }

                            fieldRow = {
                                direct_id: jsonFieldList.direct_id,
                                data_version: jsonFieldList.data_version,
                                inspection_date: jsonFieldList.inspection_date, 
                                detail_link: detail_link,
                                field_no: jsonFieldList.field_no,
                                field_id: jsonFieldList.field_id,
                                shooting_result: jsonFieldList.shooting_result,
                                request_date: jsonFieldList.request_date,
                                analysis_result: analysis_result,
                                analysis_result_display: analysis_result_display,
                                inspection_user_id: jsonFieldList.inspection_user_id,
                                inspection_result: inspection_result,
                                inspection_result_display: inspection_result_display,
                                inspection_error_code: inspection_error_code,
                                inspection_error_code_display: inspection_error_code_display,
                                inspection_other_error_comment: jsonFieldList.inspection_other_error_comment,
                                modify_flag: modify_flag ,
                                delivery_user_id: jsonFieldList.delivery_user_id,
                                delivery_error_flag: jsonFieldList.delivery_error_flag,
                                delivery_date: jsonFieldList.delivery_date,
                                inspection_biko: jsonFieldList.inspection_biko
                            }
                            this.fieldElementData.push(fieldRow);
                        }
                    }
                    this.executeResult = {
                        resultArray: null,
                        resultDataSorce: new MatTableDataSource<FieldRow>(this.fieldElementData),
                        totalCount: jsonResult.numOfElements,
                        result: true,
                        message: "API Response OK"
                    };  
                    resolve(this.executeResult);
                },
                error => {
                    console.log("<<DbdataService>>---------getFieldList() error");
                    console.log(error)
                    reject(error);
                }
            );
        });
    }

    getDirectDetail(direct_id: string): Promise<any> {
        console.log("<<DbdataService>>---------getDirectDetail() --------------");
        let headers = new Headers({ 'x-api-key': environment.x_api_key });
        let apiParams = new URLSearchParams();
        apiParams.append('direct_id', direct_id);
        let options = new RequestOptions({ headers: headers, params: apiParams });
        return new Promise((resolve, reject) => { 
            this.http.get(environment.api_url + "/directs/detail",options).subscribe(
                response => {
                    console.log("<<DbdataService>>---------getDirectDetail() response--------------");
                    console.log(response);
                    let jsonResult = JSON.parse(response.text());
                    console.log("statusCode = " + jsonResult.statusCode);
                    this.executeResult = {
                        resultArray: jsonResult.results,
                        resultDataSorce: null,
                        totalCount: "1",
                        result: true,
                        message: "API Response OK"
                    };  
                    resolve(this.executeResult);
                },
                error => {
                    console.log("<<DbdataService>>---------getDirectDetail() error");
                    console.log(error)
                    reject(error);
                }
            );
        })
    }

    getFieldDetail(direct_id:string, field_id:string):Promise<any> {
        console.log("<<DbdataService>>---------getFieldDetail() --------------");
        let headers = new Headers({ 'x-api-key': environment.x_api_key });
        let apiParams = new URLSearchParams();
        apiParams.append('direct_id', direct_id);
        apiParams.append('field_id', field_id);
        let options = new RequestOptions({ headers: headers, params: apiParams });
        return new Promise((resolve, reject) => { 
            this.http.get(environment.api_url + "/fields",options).subscribe(
                response => {
                    console.log("<<DbdataService>>---------getFieldDetail() response");
                    let jsonResult = JSON.parse(response.text());
                    this.executeResult = {
                        resultArray: jsonResult.results,
                        resultDataSorce: null,
                        totalCount: "1",
                        result: true,
                        message: "API Response OK"
                    };  
                    resolve(this.executeResult);

                },
                error => {
                    console.log("<<DbdataService>>---------getFieldDetail() error");
                    console.log(error)
                    reject(error);
                }
            );
        });
    }

    getFieldHistorys(direct_id: string, field_id: string, data_version: string ):Promise<any> {
        console.log("<<DbdataService>>---------getFieldHistorys() --------------");
        console.log("direct_id = " + direct_id + " field_id = " + field_id);

        let headers = new Headers({ 'x-api-key': environment.x_api_key });
        let apiParams = new URLSearchParams();
        apiParams.append('direct_id_and_field_id', direct_id + field_id);
        if (data_version != "") {
            apiParams.append('data_version', data_version);
        }
        let options = new RequestOptions({ headers: headers, params: apiParams });

        return new Promise((resolve, reject) => { 
            this.http.get(environment.api_url + "/field-histories",options).subscribe(
                response => {

                    console.log("<<DbdataService>>---------getFieldHistorys() response");
                    let jsonResult = JSON.parse(response.text());
                    console.log("statusCode = " + jsonResult.statusCode);

                    this.executeResult = {
                        resultArray: jsonResult.results,
                        resultDataSorce: null,
                        totalCount: "1",
                        result: true,
                        message: "API Response OK"
                    };  
                    resolve(this.executeResult);
                },
                error => {
                    console.log("<<DbdataService>>---------getFieldHistorys() error");
                    console.log(error)
                    reject(error);
                }
            );
        });
    }

    getStatisticsCsv(direct_id: string, field_id: string, data_version: string ):Promise<any> {
        console.log("<<DbdataService>>---------getStatisticsCsv() --------------");
        console.log("direct_id = " + direct_id + " field_id = " + field_id);

        return new Promise((resolve, reject) => { 
             this.http.get(environment.s3_bucket + direct_id + "/" + field_id + "/" + data_version + "/" + CommonConst.CSV_FILE_STATISTICS + field_id + CommonConst.CSV_FILE_EXTENTION).subscribe(
                response => {
                    console.log("<<DbdataService>>---------getStatisticsCsv() response");
                    resolve(response.text());
                    
                },
                error => {
                    console.log("<<DbdataService>>---------getStatisticsCsv() error");
                    console.log(error)
                    reject(error)
                }
            );
        });
    }

    getLocationCsv(direct_id: string, field_id: string, data_version: string ):Promise<any> {
        console.log("<<DbdataService>>---------getStatisticsCsv() --------------");
        console.log("direct_id = " + direct_id + " field_id = " + field_id);

        return new Promise((resolve, reject) => { 
            this.http.get(environment.s3_bucket + direct_id + "/" + field_id + "/" + data_version + "/" + CommonConst.CSV_FILE_LOCATION + field_id + CommonConst.CSV_FILE_EXTENTION).subscribe(
                response => {
                    console.log("<<DbdataService>>---------getLocationCsv() response");
                    resolve(response.text());
                },
                error => {
                    console.log("<<DbdataService>>---------getLocationCsv() error");
                    console.log(error)
                    reject(error);
                }
            );
        });
    }

    putDirectCalc(direct_id: string, user_id: string ): Promise<any> {
        console.log("<<DbdataService>>---------putDirectCalc() --------------");
        let headers = new Headers({ 'x-api-key': environment.x_api_key });
        let options = new RequestOptions({ headers: headers});
        let body: any = {
            direct_id: direct_id,
            update_user_id: user_id
        };
        return new Promise((resolve, reject) => { 
            this.http.put(environment.api_url + "/directs/calc", body, options).subscribe(
                response => {
                    console.log("<<DbdataService>>---------putDirectCalc() response");
                    console.log(response.text());
                    let jsonResult = JSON.parse(response.text());
                    console.log("statusCode = " + jsonResult.statusCode);
                    this.executeResult = {
                        resultArray: null,
                        resultDataSorce: null,
                        totalCount: null,
                        result: true,
                        message: "API Response success"
                    };  
                    resolve(this.executeResult);
                },
                error => {
                    console.log("<<DbdataService>>---------putDirectCalc() error");
                    console.log(error)
                    reject(error);
                }
            );
        });
    }

    putFieldDelivery(direct_id: string, field_id: string, delivery_error_flag: string, user_id: string ): Promise<any> {
        console.log("<<DbdataService>>---------putFieldDelivery() --------------");
        let headers = new Headers({ 'x-api-key': environment.x_api_key });
        let options = new RequestOptions({ headers: headers});
        let body: any = {
            direct_id: direct_id,
            field_id: field_id,
            delivery_error_flag: delivery_error_flag,
            update_user_id: user_id
        };
        return new Promise((resolve, reject) => { 
            this.http.put(environment.api_url + "/fields/delivery", body, options).subscribe(
                response => {
                    console.log("<<DbdataService>>---------putFieldDelivery() response");
                    console.log(response.text());
                    let jsonResult = JSON.parse(response.text());
                    console.log("statusCode = " + jsonResult.statusCode);
                    this.executeResult = {
                        resultArray: null,
                        resultDataSorce: null,
                        totalCount: null,
                        result: true,
                        message: "API Response success"
                    };  
                    resolve(this.executeResult);
                },
                error => {
                    console.log("<<DbdataService>>---------putFieldDelivery() error");
                    console.log(error)
                    reject(error);
                }
            );
        });
    }

    putFieldHistoryDelivery(direct_id: string, field_id: string, data_version: string, delivery_error_flag: string, user_id: string ): Promise<any> {
        console.log("<<DbdataService>>---------putFieldHistoryDelivery() --------------");
        let headers = new Headers({ 'x-api-key': environment.x_api_key });
        let options = new RequestOptions({ headers: headers});
        let body: any = {
            direct_id_and_field_id: direct_id + field_id,
            data_version: parseInt(data_version),
            delivery_error_flag: delivery_error_flag,
            update_user_id: user_id
        };
        return new Promise((resolve, reject) => { 
            this.http.put(environment.api_url + "/field-histories/delivery", body, options).subscribe(
                response => {
                    console.log("<<DbdataService>>---------putFieldHistoryDelivery() response");
                    console.log(response.text());
                    let jsonResult = JSON.parse(response.text());
                    console.log("statusCode = " + jsonResult.statusCode);
                    this.executeResult = {
                        resultArray: null,
                        resultDataSorce: null,
                        totalCount: null,
                        result: true,
                        message: "API Response success"
                    };  
                    resolve(this.executeResult);
                },
                error => {
                    console.log("<<DbdataService>>---------putFieldHistoryDelivery() error");
                    console.log(error)
                    reject(error);
                }
            );
        });
    }

    putFieldComment(direct_id: string, field_id: string, modify_flag: string, inspection_biko: string, username: string):Promise<any> {
        console.log("<<DbdataService>>---------putFieldComment() --------------");
        if (inspection_biko === "undefined" || inspection_biko == "" || inspection_biko == null) {
            inspection_biko = null
        } else {
            inspection_biko = this.escapeString(inspection_biko)
        }

        let headers = new Headers({ 'x-api-key': environment.x_api_key });
        let options = new RequestOptions({ headers: headers});
        let body: any = {
            direct_id: direct_id,
            field_id: field_id,
            update_user_id: username,
            updates: {
                modify_flag: modify_flag,
                inspection_biko: inspection_biko                
            }
        };
        return new Promise((resolve, reject) => { 
            this.http.put(environment.api_url + "/fields", body, options).subscribe(
                response => {
                    console.log("<<DbdataService>>---------putFieldComment() response");
                    console.log(response.text());
                    let jsonResult = JSON.parse(response.text());
                    console.log("statusCode = " + jsonResult.statusCode);
                    this.executeResult = {
                        resultArray: null,
                        resultDataSorce: null,
                        totalCount: null,
                        result: true,
                        message: "API Response success"
                    };  
                    resolve(this.executeResult);
                },
                error => {
                    console.log("<<DbdataService>>---------putFieldComment() error");
                    console.log(error)
                    reject(error);
                }
            );
        });
    }

    putFieldHistoryComment(direct_id: string, field_id: string, data_version: string,modify_flag: string, inspection_biko: string, username: string):Promise<any> {
        console.log("<<DbdataService>>---------putFieldHistoryComment() --------------");
        if (inspection_biko == "") { inspection_biko = "NULL";}
        let headers = new Headers({ 'x-api-key': environment.x_api_key });
        let options = new RequestOptions({ headers: headers});
        let body: any = {
            direct_id_and_field_id: direct_id + field_id,
            data_version: Number(data_version),
            update_user_id: username,
            updates: {
                modify_flag: modify_flag,
                inspection_biko: inspection_biko                
            }
        };
        return new Promise((resolve, reject) => { 
            this.http.put(environment.api_url + "/field-histories ", body, options).subscribe(
                response => {
                    console.log("<<DbdataService>>---------putFieldHistoryComment() response");
                    console.log(response.text());
                    let jsonResult = JSON.parse(response.text());
                    console.log("statusCode = " + jsonResult.statusCode);
                    this.executeResult = {
                        resultArray: null,
                        resultDataSorce: null,
                        totalCount: null,
                        result: true,
                        message: "API Response success"
                    };  
                    resolve(this.executeResult);
                },
                error => {
                    console.log("<<DbdataService>>---------putFieldHistoryComment() error");
                    console.log(error)
                    reject(error);
                }
            );
        });
    }

    putFieldStatus(
        direct_id: string, 
        field_id: string, 
        username: string,
        inspection_result: string, 
        modify_flag: string, 
        inspection_biko: string, 
        inspection_error_code: string, 
        inspection_other_error_comment: string 
    ):Promise<any> {
        console.log("<<DbdataService>>---------putFieldStatus() --------------");

        if (inspection_biko === "undefined" || inspection_biko == "" || inspection_biko == null) {
            inspection_biko = null
        } else {
            inspection_biko = this.escapeString(inspection_biko)
        }

        if (inspection_error_code === "undefined" || inspection_error_code == "") {
            inspection_error_code = null
        }
        if (inspection_other_error_comment === "undefined" || inspection_other_error_comment == "" || inspection_other_error_comment == null) {
            inspection_other_error_comment = null
        } else {
            inspection_other_error_comment = this.escapeString(inspection_other_error_comment)
        }

        let headers = new Headers({ 'x-api-key': environment.x_api_key });
        let options = new RequestOptions({ headers: headers});
        let body: any;
        if (inspection_result == CommonConst.INSPECTION_RESULT_PENDING) {
            body = {
                direct_id: direct_id,
                field_id: field_id,
                update_user_id: username,
                updates: {
                    inspection_result: inspection_result,
                    inspection_date: null,
                    inspection_user_id: username,                    
                    modify_flag: modify_flag,
                    inspection_biko: inspection_biko,
                    inspection_error_code: null,
                    inspection_other_error_comment: null
                }
            };    
        } else {
            let now = new Date();
            var formatted = now.toLocaleString('ja-JP');
            body = {
                direct_id: direct_id,
                field_id: field_id,
                update_user_id: username,
                updates: {
                    inspection_result: inspection_result,
                    inspection_date: formatted,
                    inspection_user_id: username,                    
                    modify_flag: modify_flag,
                    inspection_biko: inspection_biko,
                    inspection_error_code: inspection_error_code,
                    inspection_other_error_comment: inspection_other_error_comment
                }
            };   
        }
        return new Promise((resolve, reject) => { 
            this.http.put(environment.api_url + "/fields", body, options).subscribe(
                response => {
                    console.log("<<DbdataService>>---------putFieldStatus() response");
                    console.log(response.text());
                    let jsonResult = JSON.parse(response.text());
                    console.log("statusCode = " + jsonResult.statusCode);
                    this.executeResult = {
                        resultArray: null,
                        resultDataSorce: null,
                        totalCount: null,
                        result: true,
                        message: "API Response success"
                    };  
                    resolve(this.executeResult);
                },
                error => {
                    console.log("<<DbdataService>>---------putFieldStatus() error");
                    console.log(error)
                    reject(error);
                }
            );
        });
    }

    putFieldHistoryStatus(
        direct_id: string, 
        field_id: string, 
        data_version: string,
        username: string,
        inspection_result: string, 
        modify_flag: string, 
        inspection_biko: string, 
        inspection_error_code: string, 
        inspection_other_error_comment: string 
    ):Promise<any> {
        console.log("<<DbdataService>>---------putFieldHistoryStatus() --------------");
        let headers = new Headers({ 'x-api-key': environment.x_api_key });
        let options = new RequestOptions({ headers: headers});

        if (inspection_biko == "") {
            inspection_biko = "NULL"
        } else {
            inspection_biko = this.escapeString(inspection_biko)
        }

        if (inspection_error_code == "") { inspection_error_code = "NULL";}
        if (inspection_other_error_comment == "") {
            inspection_other_error_comment = "NULL"
        } else{
            inspection_other_error_comment = this.escapeString(inspection_other_error_comment)
         }

        let body: any;
        if (inspection_result == CommonConst.INSPECTION_RESULT_PENDING) {
            body = {
                direct_id_and_field_id: direct_id + field_id,
                data_version: Number(data_version),
                update_user_id: username,
                updates: {
                    inspection_result: inspection_result,
                    inspection_date: "NULL",
                    inspection_user_id: username,                    
                    modify_flag: modify_flag,
                    inspection_biko: inspection_biko,
                    inspection_error_code: "NULL",
                    inspection_other_error_comment: "NULL"
                }
            };    
        } else {
            let now = new Date();
            var formatted = now.toLocaleString('ja-JP');
            body = {
                direct_id_and_field_id: direct_id + field_id,
                data_version: Number(data_version),
                update_user_id: username,
                updates: {
                    inspection_result: inspection_result,
                    inspection_date: formatted,
                    inspection_user_id: username,                    
                    modify_flag: modify_flag,
                    inspection_biko: inspection_biko,
                    inspection_error_code: inspection_error_code,
                    inspection_other_error_comment: inspection_other_error_comment
                }
            };   
        }

        return new Promise((resolve, reject) => { 
            this.http.put(environment.api_url + "/field-histories ", body, options).subscribe(
                response => {
                    console.log("<<DbdataService>>---------putFieldHistoryStatus() response");
                    console.log(response.text());
                    let jsonResult = JSON.parse(response.text());
                    console.log("statusCode = " + jsonResult.statusCode);
                    this.executeResult = {
                        resultArray: null,
                        resultDataSorce: null,
                        totalCount: null,
                        result: true,
                        message: "API Response success"
                    };  
                    resolve(this.executeResult);
                },
                error => {
                    console.log("<<DbdataService>>---------putFieldHistoryStatus() error");
                    console.log(error)
                    reject(error);
                }
            );
        });
    }

    executeDelivery(
        direct_id: string, 
        field_id: string, 
        data_version: string, 
        inspection_result_data: string,
        user_id: string
    ):Promise<any> {
        console.log("<<DbdataService>>---------executeDelivery() --------------");
        console.log("field_id = " + field_id);
        console.log("inspection_result_data = " + inspection_result_data);

        let headers = new Headers(
            { 'x-api-key': environment.x_api_key ,
              'Content-Type':'application/json'
            });
        let options = new RequestOptions({ headers: headers});

        let body: any = {
            direct_id_and_field_id: direct_id + field_id,
            data_version: data_version,
            inspection_result_data: inspection_result_data,
            user_id: user_id
        };

        return new Promise((resolve, reject) => { 
            this.http.post(environment.api_url + "/delivery", body, options).subscribe(
                response => {
                    console.log("<<DbdataService>>---------executeDelivery() response");
                    let jsonResult = JSON.parse(response.text());
                    console.log("statusCode = " + jsonResult.statusCode);

                    if( jsonResult.statusCode == "200") {
                        this.executeResult = {
                            resultArray: null,
                            resultDataSorce: null,
                            totalCount: null,
                            result: true,
                            message: "API Response success"
                        };      
                    } else {
                        console.log("messages = " + jsonResult.messages);
                        this.executeResult = {
                            resultArray: null,
                            resultDataSorce: null,
                            totalCount: null,
                            result: false,
                            message: "API Response error"
                        };  
                    }

                    resolve(this.executeResult);

                },
                error => {
                    console.log("<<DbdataService>>---------executeDelivery() error");
                    console.log(error)
                    reject(error);
                }
            );
        });
    }
}
