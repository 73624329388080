export class MessageConst {
    public static readonly LOGIN_ERROR        = "ログインID またはパスワードが違います。";
    public static readonly LOGIN_NO_LOGINID   = "ログインIDが入力されていません。";
    public static readonly LOGIN_NO_PASSWORD  = "パスワードが入力されていません。";

    public static readonly CHANGEPASS_NO_LOGINID          = "ログインIDが入力されていません。";
    public static readonly CHANGEPASS_INVALID_KEY         = "無効な確認キーが入力されました。";
    public static readonly CHANGEPASS_NO_COMFIRM_KEY      = "確認キーが入力されていません。";
    public static readonly CHANGEPASS_EXPIRED_COMFIRM_KEY = "確認キーの有効期限が切れています。";
    public static readonly CHANGEPASS_NO_NEWPASSWORD      = "新しいパスワードが入力されていません。";
    public static readonly CHANGEPASS_INVALIDFORMAT_NEWPASSWORD  = "新しいパスワードのフォーマットが不正です。";
    public static readonly CHANGEPASS_NO_REINPUTNEWPASSWORD      = "新しいパスワード(再入力)が入力されていません。";
    public static readonly CHANGEPASS_UNMATCHFORMAT_NEWPASSWORD  = "新しいパスワードが再入力と一致していません。";
    public static readonly CHANGEPASS_OK                         = "パスワードを変更しました。";
    public static readonly CHANGEPASS_NG                         = "パスワード変更に失敗しました。";

    public static readonly INSPECTIONDETAIL_OVERLENGTH_COMMENT         = "備考の入力文字数が超過しています。";

    public static readonly DELIVERYDETAIL_OVERLENGTH_COMMENT           = "備考の入力文字数が超過しています。";
    public static readonly DELIVERYDETAIL_OVERLENGTH_FEINFO            = "エラーコードの入力文字数が超過しています。";
    public static readonly DELIVERYDETAIL_OVERLENGTH_DELIVERY_COMMENT  = "納品コメントの入力文字数が超過しています。";

    public static readonly RESET_PASS_SUCCESS   = "パスワード変更の案内メールを送信しました。";
    public static readonly RESET_PASS_ERROR     = "登録されていないユーザー名です。";

    public static readonly MODAL_INSPECTION_NG     = "検品処理中にエラーが発生しました。";
    public static readonly MODAL_DELIVERY_CONFIRM  = "納品処理を開始しますか？";
    public static readonly MODAL_DELIVERY_CHECK_NG = "納品できない圃場があります。圃場一覧をご確認ください。";
    public static readonly MODAL_DELIVERY_PROGRESS = "納品処理中です。";
    public static readonly MODAL_DELIVERY_OK       = "納品完了しました。";
    public static readonly MODAL_DELIVERY_NG       = "納品処理中にエラーが発生しました。";
    public static readonly MODAL_DELIVERY_FARM_NG  = "納品に失敗した圃場があります。";
    public static readonly MODAL_DELIVERY_STATUS_NG = "未検品の圃場があります。";
    public static readonly MODAL_SEND_EMAIL_OK     = "パスワードリセットメールを送信しました。"
    public static readonly MODAL_CHANGEPASSWORD_OK = "パスワードを変更しました。"

    public static readonly MODAL_INITIAL_NG           = "初期表示処理中にエラーが発生しました。";
    public static readonly MODAL_SEARCH_NG            = "検索処理中にエラーが発生しました。";
    public static readonly MODAL_UPDATE_NG            = "更新処理中にエラーが発生しました。";
    
    public static readonly MODAL_DETAIL_SEARCH_NG     = "詳細情報取得処理中にエラーが発生しました。";
    public static readonly MODAL_SEARCH_TOTALCOUNT_NG = "件数取得処理中にエラーが発生しました。";
    
    public static readonly MODAL_INSPECTION_DETAIL_UPDATE_OK = "備考・エラーコードを更新しました。";
    public static readonly MODAL_INSPECTION_DETAIL_UPDATE_NG = "備考・エラーコードを更新中にエラーが発生しました。";
    public static readonly MODAL_DELIVERY_DETAIL_UPDATE_OK   = "納品コメントを更新しました。"
    public static readonly MODAL_DELIVERY_DETAIL_UPDATE_NG   = "納品コメントを更新中にエラーが発生しました。";

    public static readonly MODAL_ASSIGN_DETAIL_UPDATE_NG     = "指示ID・担当者情報の更新中にエラー発生しました。";

    public static readonly SYSTEM_ERROR_MESSAGE = "システムエラー発生しました。";

}
