import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldListComponent } from '../field-list/field-list.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { RunningDialogComponent } from '../../dialog/running-dialog/running-dialog.component';
import { DirectCondition } from '../../model/direct-condtion';
import { DirectRow } from '../../model/direct-row';
import { DirectUser } from '../../model/direct-user';
import { DbdataService } from '../../service/dbdata.service';
import { MessageDialogComponent } from '../../dialog/message-dialog/message-dialog.component';
import { MessageConst } from '../../constant/message-const';
import { CognitoService } from '../../service/cognito.service';
import { Router } from '@angular/router';
import { CommonConst } from '../../constant/common-const';

/**
 * <pre>
 *   Direct ID list screen component<br>
 * <br>
 *   Update Date         Update User         Contents Of Update<br> 
 *   2019/02/17          S.Sakamoto          Create New<br>
 * </pre>
 */
@Component({
    selector: 'app-direct-list',
    templateUrl: './direct-list.component.html',
    styleUrls: ['./direct-list.component.css']
})
export class DirectListComponent implements OnInit {
    title: string;
    str_dilect_list_style='direct_list_display';
    @ViewChild(FieldListComponent,{static: false}) protected fieldListComponent: FieldListComponent;
    directCondition: DirectCondition;
    maxCount: number;
    elementData_0: DirectRow[] = [];
    displayedColumns: string[] = ['detail_link' , 'direct_id' , 'contract_kind' , 'scheduled_date' , 'prefecture_name' , 'camera_kind' , 'latest_request_date' , 'work_kind' , 'direct_user_id' , 'request_number_of_field' , 'shooting_number_of_field' , 'analysis_error_number_of_field' , 'non_inspection_number_of_field' , 'non_delivery_number_of_field' , 'delivery_number_of_field' , 'direct_comment' ];
    direct_id_dataSource: MatTableDataSource<DirectRow>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    directUserIds: DirectUser[];
    dialogRef;
    dialogRef2: MatDialogRef<MessageDialogComponent>
    username: string;

    constructor(
        private cognito: CognitoService, 
        private router: Router, 
        public dialog: MatDialog, 
        private dbdataService: DbdataService
    ) { 
        console.log("DirectListComponent------------constructor() -------------");
        this.title = CommonConst.TITLE;
        this.cognito.isAuthenticated()
        .then((res) => {
            this.username = res["idToken"]["payload"]["cognito:username"];
        })
        .catch((err) => {
            console.log(err);
            this.router.navigate(['login']);
        });

        this.directCondition = new DirectCondition();
        this.direct_id_dataSource = new MatTableDataSource<DirectRow>(this.elementData_0);
    }

    ngOnInit() {
        console.log("DirectListComponent------------ngOnInit() -------------");
        this.getUsers();
        this.clearCondition();
        this.direct_id_dataSource.sort = this.sort;
        this.maxCount = 0;
        console.log("DirectListComponent------------ngOnInit() -------------");

    }

    goToFieldList(direct_id: string) {
        console.log("DirectListComponent------------goToFieldList() -------------");
        console.log("Selected direct_id = " + direct_id)
        this.str_dilect_list_style='direct_list_hidden';
        this.fieldListComponent.comeFromDirectList(direct_id);
    }

    comeFromFieldList() {
        console.log("DirectListComponent------------comeFromFieldList() -------------");
        this.str_dilect_list_style = 'direct_list_display';
        this.getDirectLists();
    }

    openRunningDialog(): void {
        console.log("DirectListComponent------------openRunningDialog() -------------");
        this.dialogRef = this.dialog.open(RunningDialogComponent, {disableClose: true});
    }

    closeRunningDialog(): void {
        console.log("DirectListComponent------------closeRunningDialog() -------------");
        this.dialogRef.close('');
    }

    clearCondition() {
        console.log("DirectListComponent------------clearCondition() -------------");
        // initial condition value
        this.directCondition.direct_id = "";
        this.directCondition.scheduled_date_from = "";
        this.directCondition.scheduled_date_to = "";
        this.directCondition.latest_request_date_from = "";
        this.directCondition.latest_request_date_to = "";
        this.directCondition.direct_user_id = "";
        this.directCondition.work_kind_normal = true;
        this.directCondition.work_kind_marker = false;
        this.directCondition.work_kind_csv = false;
        this.directCondition.work_kind_test = false;
        this.directCondition.work_kind_cancel = false;
        this.directCondition.option = false;
        return;
    }

    getUsers() {
        console.log("DirectListComponent------------getUsers() -------------");
        this.dbdataService.getUsers()
        .then((result)=> {
            console.log("DirectListComponent------------getUsers() result success-------------");
            this.directUserIds = result.resultArray;
        })
        .catch((err)=> {
            console.log("DirectListComponent------------getUsers() error-------------");
            // URL, x_api_key error
            console.log(err.text());
            let jsonResult = JSON.parse(err.text());
            if (typeof jsonResult.statusCode === "undefined") {
                console.log("system error");
            } else {
                console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
            }
            this.openMessageDialog(MessageConst.MODAL_INITIAL_NG); 
        });
    }

    getDirectLists() {
        var start = performance.now();//delete
        console.log("DirectListComponent------------getDirectLists() -------------");
        this.openRunningDialog();
        this.dbdataService.getDirectList(this.directCondition)
        .then((result)=> {
            console.log("DirectListComponent------------getDirectLists() result-------------");
            this.direct_id_dataSource = result.resultDataSorce;
            this.direct_id_dataSource.paginator = this.paginator;
            this.direct_id_dataSource.sort = this.sort;
            this.maxCount = result.totalCount;
            this.closeRunningDialog();
            var end = performance.now();
            console.log( 'exec time = ' + (end - start) + 'msec' );
        })
        .catch((err)=> {
            console.log("DirectListComponent------------getDirectLists() err-------------");
            // URL, x_api_key error
            console.log(err.text());
            let jsonResult = JSON.parse(err.text());
            if (typeof jsonResult.statusCode === "undefined") {
                console.log("system error");
            } else {
                console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
            }
            this.closeRunningDialog();
            this.openMessageDialog(MessageConst.MODAL_SEARCH_NG); 
        });
    }

    changeDirectIdUser(event: any, no: number ) {
        console.log("DirectListComponent------------changeDirectIdUser() -------------");
        this.openRunningDialog();
        console.log("no = " + no);
        console.log("selected direct_user_id = " + event.value);
        console.log("direct_id = " + this.direct_id_dataSource.data[no].direct_id);

        this.dbdataService.putDirectUser(this.direct_id_dataSource.data[no].direct_id, event.value, this.username)
        .then((result)=> {
            console.log("DirectListComponent------------changeDirectIdUser() result-------------");
            this.closeRunningDialog();
        })
        .catch((err)=> {
            // URL, x_api_key error
            console.log(err.text());
            let jsonResult = JSON.parse(err.text());
            if (typeof jsonResult.statusCode === "undefined") {
                console.log("system error");
            } else {
                console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
            }
            this.closeRunningDialog();
            this.openMessageDialog(MessageConst.MODAL_UPDATE_NG); 
            
        });
    }
    
    openMessageDialog(message: string) :void {
        console.log("    DirectListComponent-------------openMessageDialog() ------------");
        this.dialogRef2 = this.dialog.open(MessageDialogComponent,{
            data: {dialogMessage: message},
            disableClose: true
        });
    }

}
