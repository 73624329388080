import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageDialogComponent } from '../../dialog/message-dialog/message-dialog.component';
import { RunningDialogComponent } from '../../dialog/running-dialog/running-dialog.component';
import { InfomationDialogComponent } from '../../dialog/infomation-dialog/infomation-dialog.component';
import { MessageConst } from '../../constant/message-const';
import { CommonConst } from '../../constant/common-const';
import { DbdataService } from '../../service/dbdata.service';
import { environment } from './../../../environments/environment';
import { ContinuousInspection } from '../../model/continuous-inspection';

/**
 * <pre>
 *   Inspection screen component<br>
 * <br>
 *   Update Date         Update User         Contents Of Update<br> 
 *   2019/02/17          S.Sakamoto          Create New<br>
 * </pre>
 */
export interface ErrorCode {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-inspection',
    templateUrl: './inspection.component.html',
    styleUrls: ['./inspection.component.css']
})

export class InspectionComponent implements OnInit {
    title: string;
    str_inspection_style = 'inspection_hidden';
    @Output() gotoFieldList = new EventEmitter();
    @Input() usernameFromFieldList: string;

    dialogRefRunning: MatDialogRef<RunningDialogComponent>;
    dialogRef2: MatDialogRef<MessageDialogComponent>;
    dialogRef3: MatDialogRef<InfomationDialogComponent>;

    continuousInspections: ContinuousInspection[];

    totalCountDisplay: number;
    currentCountDisplay: number;

    prevCtl: boolean;
    nextCtl: boolean;

    errorCodeList: ErrorCode[];

    errorCodeCtl: boolean;
    otherErrorCtl: boolean;
    imageModifyMapCtl: boolean;
    imageModifyMapPori: boolean;
    updateBtnCtl: boolean;
    pendingBtnCtl: boolean;
    ngBtnCtl: boolean;
    okBtnCtl: boolean;
    guideImgCtr: boolean;
    googleMapCtl: boolean;

    url_visible: string;
    url_ndvi_leaf_color: string;
    url_plant_rate: string;
    url_ndvi_plant_rate: string;
    url_Guide: string;
    url_plot: string;

    direct_id: string;
    field_no: string;
    field_id: string;
    data_version: string;
    shooting_result: string;
    request_date: string;
    analysis_result: string;
    inspection_user_id: string;
    inspection_result: string;
    inspection_result_display: string;
    inspection_error_code: string;
    inspection_error_code_initial: string;
    inspection_other_error_comment: string;
    inspection_other_error_comment_initial: string;
    modify_flag_initial: string; 
    modify_flag: string; 
    modify_check_map: boolean;
    modify_check_porigon: boolean;

    inspection_date: string;
    delivery_user_id: string;
    delivery_error_flag: string;
    delivery_date: string;
    inspection_biko_initial: string;
    inspection_biko: string;

    ndvi_reaf_color_average: string;
    ndvi_reaf_color_variation: string;
    ndvi_reaf_color_coverage: string;
    planting_rate_stem_number_average: string;
    planting_rate_stem_number_variation: string;
    planting_rate_stem_number_coverage: string;
    ndvi_planting_rate_average: string;
    ndvi_planting_rate_variation: string;
    ndvi_planting_rate_coverage: string;

    guide_error_flag: boolean;
    plot_statistics_mode_flag: boolean;
    statistics_list: any[];
    google_location: String;

    constructor(
        public dialog: MatDialog,
        private dbdataService: DbdataService
    ) {
        console.log("InspectionComponent-------------constructor() ------------");
        this.title = CommonConst.TITLE;

    }

    ngOnInit() {
        console.log("InspectionComponent-------------ngOnInit() ------------");
        this.errorCodeList = [];
        let i: number = 0;
        for (let errorCodeList of CommonConst.ERROR_CODE_LIST) {

            let errorCode: ErrorCode;
            if (i == 0) {
                errorCode = {
                    value: errorCodeList.key ,viewValue: errorCodeList.value
                }    
            } else {
                errorCode = {
                    value: errorCodeList.key ,viewValue: errorCodeList.key + ":" + errorCodeList.value
                }    
            }
            this.errorCodeList[i]= errorCode;
            i++;
        }
    }

    comeFromFieldList(directId: string, fieldId: string, data_version: string, continuousInspections){
        console.log("InspectionComponent-------------comeFromFieldList() ------------");
        console.log("directId = " + directId + " fieldId = "+ fieldId );
        this.openRunningDialog();
        this.clearDisplayContents();
        this.direct_id = directId;
        this.field_id = fieldId;
        this.data_version = data_version;

        this.continuousInspections = continuousInspections;
        this.str_inspection_style = 'inspection_display';
        this.getFieldDetail(directId, fieldId);
    }

    goToFieldList(){
        console.log("InspectionComponent-------------goToFieldList() ------------");
        console.log("this.inspection_error_code_initial = " + this.inspection_error_code_initial);
        console.log("this.inspection_error_code         = " + this.inspection_error_code);
        console.log("this.inspection_other_error_comment_initial = " + this.inspection_other_error_comment_initial);
        console.log("this.inspection_other_error_comment         = " + this.inspection_other_error_comment);
        if (this.inspection_error_code_initial != this.inspection_error_code || 
            this.inspection_other_error_comment_initial != this.inspection_other_error_comment ||
            this.inspection_biko_initial != this.inspection_biko ||
            this.modify_flag_initial != this.modify_flag
        ) {
            this.dialogRef3 = this.dialog.open(InfomationDialogComponent, {disableClose: true});
            this.dialogRef3.afterClosed().subscribe(result => {
                if(result) {
                    this.str_inspection_style = 'inspection_hidden';
                    this.gotoFieldList.emit();    
                }
            });   
        } else {
            this.str_inspection_style = 'inspection_hidden';
            this.gotoFieldList.emit();    
        }
    }

    getFieldDetail(directId: string, fieldId: string){
        console.log("InspectionComponent-------------getFieldDetail() ------------");
        console.log("directId = " + directId + ", fieldId = " + fieldId + ")");

//        this.setImageUrl();

        this.ndvi_reaf_color_average = "-";
        this.ndvi_reaf_color_variation = "-";                            
        this.ndvi_reaf_color_coverage = "-";
        this.planting_rate_stem_number_average = "-";    
        this.planting_rate_stem_number_variation = "-";
        this.planting_rate_stem_number_coverage = "-";
        this.ndvi_planting_rate_average = "-";
        this.ndvi_planting_rate_variation = "-";
        this.ndvi_planting_rate_coverage = "-";                        

        // guide Initialize
        this.guide_error_flag = false;
        this.guideImgCtr = false;
        this.url_Guide = '';

        this.dbdataService.getFieldDetail(directId, fieldId)
        .then((result)=> {
            console.log("InspectionComponent------------getFieldDetail() result success-------------");
            this.direct_id                              = result.resultArray[0].direct_id;
            this.field_id                               = result.resultArray[0].field_id;
            this.data_version                           = result.resultArray[0].data_version;

            this.setImageUrl();

            if(result.resultArray[0].field_no == null || result.resultArray[0].field_no == "") {
                result.resultArray[0].field_no = "-";
            }
            this.field_no                               = result.resultArray[0].field_no;
            if(result.resultArray[0].shooting_result == null || result.resultArray[0].shooting_result == "") {
                result.resultArray[0].shooting_result = "-";
            }
            this.shooting_result                        = result.resultArray[0].shooting_result;

            if(result.resultArray[0].request_date == null || result.resultArray[0].request_date == "") {
                result.resultArray[0].request_date = "-";
            } else {
                result.resultArray[0].request_date = result.resultArray[0].request_date.substr(0,16);
            }
            this.request_date                           = result.resultArray[0].request_date;

            if(result.resultArray[0].inspection_user_id == null || result.resultArray[0].inspection_user_id == "") {
                result.resultArray[0].inspection_user_id = "-";
            }            
            this.inspection_user_id                     = result.resultArray[0].inspection_user_id;
            if(result.resultArray[0].inspection_date == null || result.resultArray[0].inspection_date == "") {
                result.resultArray[0].inspection_date = "-";
            } else {
                result.resultArray[0].inspection_date = result.resultArray[0].inspection_date.substr(0,16);
            }
            this.inspection_date                        = result.resultArray[0].inspection_date;
            if(result.resultArray[0].delivery_user_id == null || result.resultArray[0].delivery_user_id == "") {
                result.resultArray[0].delivery_user_id = "-";
            }
            this.delivery_user_id                       = result.resultArray[0].delivery_user_id;
            if(result.resultArray[0].delivery_date == null || result.resultArray[0].delivery_date == "") {
                result.resultArray[0].delivery_date = "-";
            } else {
                result.resultArray[0].delivery_date = result.resultArray[0].delivery_date.substr(0,16);
            }
            this.delivery_date                          = result.resultArray[0].delivery_date;
            if(result.resultArray[0].delivery_error_flag == null || result.resultArray[0].delivery_error_flag == "") {
                result.resultArray[0].delivery_error_flag = "-";
            }
            this.delivery_error_flag                    = result.resultArray[0].delivery_error_flag;

            if (result.resultArray[0].inspection_error_code == null ){
                result.resultArray[0].inspection_error_code = "";
            }
            this.inspection_error_code                  = result.resultArray[0].inspection_error_code;
            this.inspection_error_code_initial          = result.resultArray[0].inspection_error_code;

            if (result.resultArray[0].inspection_other_error_comment == null ){
                result.resultArray[0].inspection_other_error_comment = "";
            }
            this.inspection_other_error_comment         = result.resultArray[0].inspection_other_error_comment;
            this.inspection_other_error_comment_initial = result.resultArray[0].inspection_other_error_comment;

            if (result.resultArray[0].inspection_biko == null ){
                result.resultArray[0].inspection_biko = "";
            }
            this.inspection_other_error_comment         = result.resultArray[0].inspection_other_error_comment;
            this.inspection_other_error_comment_initial = result.resultArray[0].inspection_other_error_comment;
            this.inspection_biko                        = result.resultArray[0].inspection_biko;
            this.inspection_biko_initial                = result.resultArray[0].inspection_biko;

            if(result.resultArray[0].analysis_result == null) {
                this.analysis_result = "";
            }
            for (let analysisResult of CommonConst.ANALYSIS_RESULT_LIST) {
                if (analysisResult["key"] == result.resultArray[0].analysis_result) {
                    this.analysis_result = analysisResult["value"];
                    break;
                }
            }

            this.inspection_result = result.resultArray[0].inspection_result;

            if(result.resultArray[0].inspection_result == null || result.resultArray[0].inspection_result == "") {
                this.inspection_result_display = "-";
            }
            for (let inspectionResult of CommonConst.INSPECTION_RESULT_LIST) {
                if (inspectionResult["key"] == result.resultArray[0].inspection_result) {
                    this.inspection_result_display = inspectionResult["value"];
                    break;
                }
            }

            if(result.resultArray[0].modify_flag == null || result.resultArray[0].modify_flag == "") {
                result.resultArray[0].modify_flag = "0";
            }
            this.modify_flag                            = result.resultArray[0].modify_flag;
            this.modify_flag_initial                    = result.resultArray[0].modify_flag;

            if (this.modify_flag == "0") {
                this.modify_check_map     = false;
                this.modify_check_porigon = false;    

            } else if (this.modify_flag == "1") {
                this.modify_check_map     = true;
                this.modify_check_porigon = false;    

            } else if (this.modify_flag == "2") {
                this.modify_check_map     = false;
                this.modify_check_porigon = true;    

            } else if (this.modify_flag == "3") {
                this.modify_check_map     = true;
                this.modify_check_porigon = true;    
            }

            Promise.all( [this.getFieldHistories(directId, fieldId),
                            this.getFieldStatistics(directId, fieldId),
                            this.getFieldLocation(directId, fieldId) ])
            .then((results)=> {
                console.log("InspectionComponent-------------Promise.all(getFieldHistorys(),getFieldStatistics(),getFieldLocation()) response------------");
                this.setDisplay();
                this.closeRunningDialog();

            })
            .catch((err)=>{
                console.log("InspectionComponent-------------Promise.all(getFieldHistorys(),getFieldStatistics(),getFieldLocation()) err------------");
                this.disableBtn();
                this.closeRunningDialog();
                this.openMessageDialog(MessageConst.MODAL_INITIAL_NG); 
            });
        })
        .catch((err)=> {
            console.log("InspectionComponent------------getFieldDetail() err-------------");
            // URL, x_api_key error
            console.log(err.text());
            let jsonResult = JSON.parse(err.text());
            if (typeof jsonResult.statusCode === "undefined") {
                console.log("system error");
            } else {
                console.log("system error " + jsonResult.statusCode + " : " + jsonResult.messages);
            }
            this.disableBtn();
            this.closeRunningDialog();
            this.openMessageDialog(MessageConst.MODAL_INITIAL_NG); 
        });
    }

    prevField(){
        console.log("InspectionComponent-------------prevField() ------------");
        console.log("this.inspection_error_code_initial = " + this.inspection_error_code_initial);
        console.log("this.inspection_error_code = " + this.inspection_error_code);
        console.log("this.inspection_other_error_comment_initial = " + this.inspection_other_error_comment_initial);
        console.log("this.inspection_other_error_comment = " + this.inspection_other_error_comment);
        console.log("this.inspection_biko_initial = " + this.inspection_biko_initial);
        console.log("this.inspection_biko = " + this.inspection_biko);
        console.log("this.modify_flag_initial = " + this.modify_flag_initial);
        console.log("this.modify_flag = " + this.modify_flag);
        if (this.inspection_error_code_initial != this.inspection_error_code || 
            this.inspection_other_error_comment_initial != this.inspection_other_error_comment ||
            this.inspection_biko_initial != this.inspection_biko ||
            this.modify_flag_initial != this.modify_flag
        ) {

            this.dialogRef3 = this.dialog.open(InfomationDialogComponent, {disableClose: true});
            this.dialogRef3.afterClosed().subscribe(result => {
                if(result) {
                    this.prevCall();
                }
            });   
        } else {
            this.prevCall();
        }
    }
    
    nextField(){
        console.log("InspectionComponent-------------nextField() ------------");

        if (this.inspection_error_code_initial != this.inspection_error_code || 
            this.inspection_other_error_comment_initial != this.inspection_other_error_comment ||
            this.inspection_biko_initial != this.inspection_biko ||
            this.modify_flag_initial != this.modify_flag
        ) {

            this.dialogRef3 = this.dialog.open(InfomationDialogComponent, {disableClose: true});
            this.dialogRef3.afterClosed().subscribe(result => {
                if(result) {
                    this.nextCall();
                }
            });   
        } else {
            this.nextCall();
        }
    }

    prevCall(){
        this.openRunningDialog();
        this.currentCountDisplay--;
        for (let i in this.continuousInspections) {
            if (this.continuousInspections[i].serial_no == this.currentCountDisplay ){
                this.field_id = this.continuousInspections[i].field_id;
                break;
            }
        }
        this.getFieldDetail(this.direct_id, this.field_id);
    }

    nextCall(){
        this.openRunningDialog();
        this.currentCountDisplay++;
        for (let i in this.continuousInspections) {
            if (this.continuousInspections[i].serial_no == this.currentCountDisplay ){
                this.field_id = this.continuousInspections[i].field_id;
                break;
            }
        }
        this.getFieldDetail(this.direct_id, this.field_id);
    }

    nextCallStatusBtn(not_pending_flag: boolean){

        if(this.currentCountDisplay == this.totalCountDisplay){ 
            this.closeRunningDialog();
            this.str_inspection_style = 'inspection_hidden';
            this.gotoFieldList.emit();
            return;
        }
        if (not_pending_flag) {
            this.continuousInspections[this.currentCountDisplay - 1].non_inspection_flag = false;
        } else {
            this.continuousInspections[this.currentCountDisplay - 1].non_inspection_flag = true;
        }
        this.currentCountDisplay++;

        for (let i in this.continuousInspections) {
            if (this.continuousInspections[i].serial_no == this.currentCountDisplay ){
                console.log("same sereal_no ");
                if (this.continuousInspections[i].non_inspection_flag) {
                    console.log("non inspection!");
                    this.field_id = this.continuousInspections[i].field_id;
                    break;
                } else {
                    console.log("currentCountDisplay ++ ");
                    this.currentCountDisplay++;
                }
            }
        }

        if(this.currentCountDisplay > this.totalCountDisplay){ 
            this.closeRunningDialog();
            this.str_inspection_style = 'inspection_hidden';
            this.gotoFieldList.emit();

        } else {
            this.getFieldDetail(this.direct_id, this.field_id);
        }
    }

    setDisplay(){
        console.log("InspectionComponent-------------setDisplay() ------------");
 
        this.updateBtnCtl = false;
        this.pendingBtnCtl = false;
    
        // error code 
        if (this.inspection_result == CommonConst.INSPECTION_RESULT_OK || 
            this.inspection_result == CommonConst.INSPECTION_RESULT_NG) {
            this.errorCodeCtl  = true;
            this.otherErrorCtl = true;
        } else {
            this.errorCodeCtl  = false;
            // other error
            if (this.inspection_error_code == CommonConst.ERROR_CODE_OTHER_ERROR) {
                this.otherErrorCtl = false;        
            } else {
                this.otherErrorCtl = true;
            }
        }

        // image modfy check box
        if (this.inspection_result == CommonConst.INSPECTION_RESULT_OK || 
            this.inspection_result == CommonConst.INSPECTION_RESULT_NG) {

            this.imageModifyMapCtl = true;
            this.imageModifyMapPori = true;
    
        } else {
            this.imageModifyMapCtl = false;
            this.imageModifyMapPori = false;
        }

        // ng button
        if (this.inspection_error_code == "" || this.inspection_error_code == null) {
            this.ngBtnCtl = true;
        } else {
            this.ngBtnCtl = false;
        }

        this.changeImageModifyCheck();

        // current no / total no
        this.totalCountDisplay = this.continuousInspections.length;
        for (let i in this.continuousInspections) {
            if (this.continuousInspections[i].field_id == this.field_id ){
                this.currentCountDisplay = this.continuousInspections[i].serial_no;
                break;
            }
        }

        // prev link , next link
        if(this.currentCountDisplay == 1){ 
            this.prevCtl = false;
        } else {
            this.prevCtl = true;
        }
        if(this.currentCountDisplay == this.totalCountDisplay){ 
            this.nextCtl = false;
        } else {
            this.nextCtl = true;
        }
    }

    changeErrorCode(event: any){
        console.log("InspectionComponent-------------changeErrorCode() ------------");
        
        if (this.inspection_error_code == CommonConst.ERROR_CODE_OTHER_ERROR) {
            this.otherErrorCtl = false;
        } else {
            this.otherErrorCtl = true;
            this.inspection_other_error_comment = "";
        }
        if (this.inspection_error_code == "") {
            this.ngBtnCtl = true;
        } else {
            this.ngBtnCtl = false;
        }
        if (this.modify_check_map || this.modify_check_porigon) {
            this.ngBtnCtl = true;
            this.okBtnCtl = true;
        }
    }

    changeImageModifyCheck() {
        console.log("InspectionComponent-------------changeImageModifyCheck() ------------");
        if (this.modify_check_map || this.modify_check_porigon) {
            this.ngBtnCtl = true;
            this.okBtnCtl = true;
        } else {
            this.ngBtnCtl = false;
            if (this.inspection_error_code == "" || this.inspection_error_code == null) {
                this.ngBtnCtl = true;
            } else {
                this.ngBtnCtl = false;
            }
            this.okBtnCtl = false;
        }

        if (this.modify_check_map == false && this.modify_check_porigon == false ) {
            this.modify_flag = "0";
        } else if (this.modify_check_map == true && this.modify_check_porigon == false) {
            this.modify_flag = "1";
        } else if (this.modify_check_map == false && this.modify_check_porigon == true) {
            this.modify_flag = "2";
        } else if (this.modify_check_map == true && this.modify_check_porigon == true) {
            this.modify_flag = "3";
        }
        console.log("modify_flag = " + this.modify_flag);
    }

    setImageUrl(){
        console.log("InspectionComponent-------------setImageUrl() ------------");
  
        this.url_visible         = environment.s3_bucket + this.direct_id + "/" + this.field_id + "/" + this.data_version + "/" + CommonConst.IMAGE_FILE_VISIBLE + this.field_id + CommonConst.IMAGE_FILE_EXTENTION;
        this.url_ndvi_leaf_color = environment.s3_bucket + this.direct_id + "/" + this.field_id + "/" + this.data_version + "/" + CommonConst.IMAGE_FILE_NDVI    + this.field_id + CommonConst.IMAGE_FILE_EXTENTION;
        this.url_plant_rate      = environment.s3_bucket + this.direct_id + "/" + this.field_id + "/" + this.data_version + "/" + CommonConst.IMAGE_FILE_VCR     + this.field_id + CommonConst.IMAGE_FILE_EXTENTION;
        this.url_ndvi_plant_rate = environment.s3_bucket + this.direct_id + "/" + this.field_id + "/" + this.data_version + "/" + CommonConst.IMAGE_FILE_NDVIX   + this.field_id + CommonConst.IMAGE_FILE_EXTENTION;
        this.url_Guide           = environment.s3_bucket + this.direct_id + "/" + this.field_id + "/" + this.data_version + "/" + CommonConst.IMAGE_FILE_GUIDE + this.field_id + CommonConst.IMAGE_FILE_EXTENTION;
        this.url_plot           = environment.s3_bucket + this.direct_id + "/" + this.field_id + "/" + this.data_version + "/" + CommonConst.IMAGE_FILE_PLOT + this.field_id + CommonConst.IMAGE_FILE_EXTENTION;

        console.log("========== url_visible = " + this.url_visible);

    }

    clearDisplayContents(){
        console.log("InspectionComponent-------------clearDisplayContents() ------------");
        this.currentCountDisplay = 0;
        this.totalCountDisplay = 0;

        this.url_visible = "";
        this.url_ndvi_leaf_color = "";
        this.url_plant_rate = "";
        this.url_ndvi_plant_rate = "";
        this.url_Guide = "";
        this.url_plot = "";

        this.direct_id = "";
        this.field_no = "";
        this.field_id = "";
        this.shooting_result = "";
        this.request_date = "";
        this.analysis_result = "";
        this.inspection_user_id = "";
        this.inspection_result = "";
        this.inspection_error_code = "";
        this.inspection_error_code_initial = "";
        this.inspection_other_error_comment = "";
        this.inspection_other_error_comment_initial = "";
        this.modify_flag = ""; 
        this.inspection_date = "";
        this.delivery_user_id = "";
        this.delivery_error_flag = "";
        this.delivery_date = "";
        this.inspection_biko = "";
        this.inspection_biko_initial = "";
    
        this.ndvi_reaf_color_average = "";
        this.ndvi_reaf_color_variation = "";
        this.ndvi_reaf_color_coverage = "";
        this.planting_rate_stem_number_average = "";
        this.planting_rate_stem_number_variation = "";
        this.planting_rate_stem_number_coverage = "";
        this.ndvi_planting_rate_average = "";
        this.ndvi_planting_rate_variation = "";
        this.ndvi_planting_rate_coverage = "";

        this.guide_error_flag = false;
        this.plot_statistics_mode_flag = false;
        this.statistics_list = [];
        this.google_location = "";
    }

    updateField(){
        console.log("InspectionComponent-------------updateField() ------------");

        this.openRunningDialog();
        this.dbdataService.putFieldComment(this.direct_id, this.field_id, this.modify_flag, this.inspection_biko, this.usernameFromFieldList)
        .then((result)=> {
            console.log("InspectionComponent------------updateField() putFieldComment result-------------");

            this.dbdataService.putFieldHistoryComment(this.direct_id, this.field_id, this.data_version, this.modify_flag, this.inspection_biko, this.usernameFromFieldList)
            .then((result)=> {
                console.log("InspectionComponent------------updateField() putFieldHistoryComment result-------------");
                this.inspection_biko_initial = this.inspection_biko;
                this.modify_flag_initial = this.modify_flag;
                this.closeRunningDialog();
            })
            .catch((err)=> {
                console.log("InspectionComponent------------updateField() putFieldHistoryComment error-------------");
                // URL, x_api_key error
                console.log(err.text());
                let jsonResult = JSON.parse(err.text());
                if (typeof jsonResult.statusCode === "undefined") {
                    console.log("system error");
                } else {
                    console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
                }
                this.closeRunningDialog();
                this.openMessageDialog(MessageConst.MODAL_UPDATE_NG); 
            });
    
        })
        .catch((err)=> {
            console.log("InspectionComponent------------updateField() putFieldComment error-------------");
            // URL, x_api_key error
            console.log(err.text());
            let jsonResult = JSON.parse(err.text());
            if (typeof jsonResult.statusCode === "undefined") {
                console.log("system error");
            } else {
                console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
            }
            this.closeRunningDialog();
            this.openMessageDialog(MessageConst.MODAL_UPDATE_NG); 
        });
    }

    execPrePending(){
        console.log("InspectionComponent-------------execPrePending() ------------");

        if (this.inspection_error_code_initial != this.inspection_error_code || 
            this.inspection_other_error_comment_initial != this.inspection_other_error_comment
        ) {

            this.dialogRef3 = this.dialog.open(InfomationDialogComponent, {disableClose: true});
            this.dialogRef3.afterClosed().subscribe(result => {
                if(result) {
                    this.execPending();
                }
            });   
        } else {
            this.execPending();
        }
    }

    execPending(){
        console.log("InspectionComponent-------------execPending() ------------");
        this.openRunningDialog();

        this.dbdataService.putFieldStatus(
            this.direct_id, 
            this.field_id, 
            this.usernameFromFieldList, 
            CommonConst.INSPECTION_RESULT_PENDING, 
            this.modify_flag, 
            this.inspection_biko, 
            "",
            ""
        )
        .then((result)=> {
            console.log("InspectionComponent------------execPending() putFieldStatus result-------------");

            this.dbdataService.putFieldHistoryStatus(
                this.direct_id, 
                this.field_id, 
                this.data_version, 
                this.usernameFromFieldList, 
                CommonConst.INSPECTION_RESULT_PENDING, 
                this.modify_flag, 
                this.inspection_biko, 
                "",
                ""
                )
            .then((result)=> {
                console.log("InspectionComponent------------execPending() putFieldHistoryStatus result-------------");

                // Recalculation
                this.dbdataService.putDirectCalc(this.direct_id,this.usernameFromFieldList)
                .then((result)=> {
                    console.log("InspectionComponent--------putDirectCalc() result-------------");

                    this.nextCallStatusBtn(false);
                })
                .catch((err)=> {
                    console.log("InspectionComponent--------putDirectCalc() error-------------");
                    // URL, x_api_key error
                    console.log(err.text());
                    let jsonResult = JSON.parse(err.text());
                    if (typeof jsonResult.statusCode === "undefined") {
                        console.log("system error");
                    } else {
                        console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
                    }
                    this.closeRunningDialog();
                    this.openMessageDialog(MessageConst.MODAL_UPDATE_NG); 
                });    
            })
            .catch((err)=> {
                console.log("InspectionComponent------------execPending() putFieldHistoryComment error-------------");
                // URL, x_api_key error
                console.log(err.text());
                let jsonResult = JSON.parse(err.text());
                if (typeof jsonResult.statusCode === "undefined") {
                    console.log("system error");
                } else {
                    console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
                }
                this.closeRunningDialog();
                this.openMessageDialog(MessageConst.MODAL_UPDATE_NG); 
            });
        })
        .catch((err)=> {
            console.log("InspectionComponent------------execPending() putFieldStatus error-------------");
            // URL, x_api_key error
            console.log(err.text());
            let jsonResult = JSON.parse(err.text());
            if (typeof jsonResult.statusCode === "undefined") {
                console.log("system error");
            } else {
                console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
            }
            this.closeRunningDialog();
            this.openMessageDialog(MessageConst.MODAL_UPDATE_NG); 
        });
    }
    
    execOk(){
        console.log("InspectionComponent-------------execOk() ------------");
        this.execOkNg(CommonConst.INSPECTION_RESULT_OK);
    }

    execNg(){
        console.log("InspectionComponent-------------execNg() ------------");
        this.execOkNg(CommonConst.INSPECTION_RESULT_NG);
    }

    execOkNg(inspection_result: string){
        console.log("InspectionComponent-------------execOkNg() ------------");
        this.openRunningDialog();

        this.dbdataService.putFieldStatus(
            this.direct_id, 
            this.field_id, 
            this.usernameFromFieldList, 
            inspection_result, 
            this.modify_flag, 
            this.inspection_biko, 
            this.inspection_error_code,
            this.inspection_other_error_comment
        )
        .then((result)=> {
            console.log("InspectionComponent------------execOkNg() putFieldStatus result-------------");

            this.dbdataService.putFieldHistoryStatus(
                this.direct_id, 
                this.field_id, 
                this.data_version, 
                this.usernameFromFieldList, 
                inspection_result, 
                this.modify_flag, 
                this.inspection_biko, 
                this.inspection_error_code,
                this.inspection_other_error_comment
            )
            .then((result)=> {
                console.log("InspectionComponent------------execOkNg() putFieldHistoryStatus result-------------");

                // Recalculation
                this.dbdataService.putDirectCalc(this.direct_id,this.usernameFromFieldList)
                .then((result)=> {
                    console.log("InspectionComponent--------execOkNg() result-------------");

                    this.nextCallStatusBtn(true);
                })
                .catch((err)=> {
                    console.log("InspectionComponent--------execOkNg() putDirectCalc() error-------------");
                    // URL, x_api_key error
                    console.log(err.text());
                    let jsonResult = JSON.parse(err.text());
                    if (typeof jsonResult.statusCode === "undefined") {
                        console.log("system error");
                    } else {
                        console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
                    }
                    this.closeRunningDialog();
                    this.openMessageDialog(MessageConst.MODAL_UPDATE_NG); 
                });
            })
            .catch((err)=> {
                console.log("InspectionComponent------------execOkNg() putFieldHistoryComment error-------------");
                // URL, x_api_key error
                console.log(err.text());
                let jsonResult = JSON.parse(err.text());
                if (typeof jsonResult.statusCode === "undefined") {
                    console.log("system error");
                } else {
                    console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
                }
                this.closeRunningDialog();
                this.openMessageDialog(MessageConst.MODAL_UPDATE_NG); 
            });
        })
        .catch((err)=> {
            console.log("InspectionComponent------------execOkNg() putFieldStatus error-------------");
            // URL, x_api_key error
            console.log(err.text());
            let jsonResult = JSON.parse(err.text());
            if (typeof jsonResult.statusCode === "undefined") {
                console.log("system error");
            } else {
                console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
            }
            this.closeRunningDialog();
            this.openMessageDialog(MessageConst.MODAL_UPDATE_NG); 
        });
    }

    openMessageDialog(message: string):void {
        console.log("InspectionComponent-------------openMessageDialog() ------------");
        this.dialogRef2 = this.dialog.open(MessageDialogComponent,{
            data: {dialogMessage: message},
            disableClose: true
        });
    }

    openRunningDialog(): void {
        console.log("InspectionComponent------------openRunningDialog() -------------");
        this.dialogRefRunning = this.dialog.open(RunningDialogComponent, {disableClose: true});
    }

    closeRunningDialog(): void {
        console.log("InspectionComponent------------closeRunningDialog() -------------");
        this.dialogRefRunning.close('');
    }

    disableBtn() {
        console.log("InspectionComponent------------disableBtn() -------------");
        this.updateBtnCtl  = true;
        this.pendingBtnCtl = true;
        this.ngBtnCtl      = true;
        this.okBtnCtl      = true;
    }

    clickVisiblePicture(){
        console.log("InspectionComponent------------clickVisiblePicture() -------------");
        if(this.guide_error_flag == false){
            // guideImg display
            this.guideImgCtr = false;
        }
    }

    clickGuidePicture(){
        console.log("InspectionComponent------------clickGuidePicture() -------------");
        // guideImg Hide
        this.guideImgCtr = true;
    }

    guidePictureError(){
        console.log("InspectionComponent------------guidePictureError() -------------");
        if(this.url_Guide != ''){
            // guideImg Hide
            this.guide_error_flag =true;
            this.guideImgCtr = true;
        }
    }

    modeChange(){
        console.log("InspectionComponent------------modeChange() -------------");
        // displayMode change
        this.plot_statistics_mode_flag = !this.plot_statistics_mode_flag;
    }


    getFieldStatistics(directId: string, fieldId: string): Promise<void> {
        console.log("InspectionComponent------------getFieldStatistics() -------------");
        console.log("direct_id = " + this.direct_id + " field_id = " + this.field_id);

        this.statistics_list = [];

        return new Promise((resolve, reject) => {
            this.dbdataService.getStatisticsCsv(directId, fieldId, this.data_version)
            .then((result)=> {
                console.log("InspectionComponent---------getFieldStatistics() getStatisticsCsv result-------------");
                console.log("result = " + result);
                try {
                    this.statistics_list = result.split("\n").filter(s => { return s != ""}).map(s => { let col = s.split(",");  return {item:col[0], data:col[1]} });
                } catch (error) {
                    console.log("system error");
                    this.statistics_list = [];
                }
                resolve();
            })
            .catch((err)=> {
                console.log("InspectionComponent---------getFieldStatistics() getStatisticsCsv noData-------------");
                this.googleMapCtl = true;
                // Not ErrorMessage
                resolve();
            });
        })
    }

    getFieldLocation(directId: string, fieldId: string): Promise<void> {
        console.log("InspectionComponent------------getFieldLocation() -------------");
        console.log("direct_id = " + this.direct_id + " field_id = " + this.field_id);

        return new Promise((resolve, reject) => {
            this.dbdataService.getLocationCsv(directId, fieldId, this.data_version)
            .then((result)=> {
                console.log("InspectionComponent---------getFieldLocation() getLocationCsv result-------------");
                console.log("result = " + result);
                try {
                    let location = result.split("\n").map(s => { let col = s.split(",");  return {lat:col[0], lon:col[1]} });
                    this.google_location = 'https://google.com/maps?q=' + location[0].lat + ',' + location[0].lon;
                    this.googleMapCtl = false;
                } catch (error) {
                    console.log("system error");
                    this.googleMapCtl = true;
                }
                resolve();
            })
            .catch((err)=> {
                console.log("InspectionComponent---------getFieldLocation() getLocationCsv noData-------------");
                this.googleMapCtl = true;
                // Not ErrorMessage
                resolve();
            });
        })
    }


    getFieldHistories(directId: string, fieldId: string): Promise<void>{
        return new Promise((resolve, reject) => {
            this.dbdataService.getFieldHistorys(directId, fieldId, this.data_version)
            .then((result)=> {
                console.log("InspectionComponent------------getFieldHistories() getFieldHistorys result success-------------");

                if (result.resultArray.length !== 0) {
                    console.log("result.resultArray[0].analysis_result_data.analysis-result = " + result.resultArray[0].analysis_result_data);

                    if (result.resultArray[0].analysis_result_data == "NULL" ) {
                        this.ndvi_reaf_color_average = "-";
                        this.ndvi_reaf_color_variation = "-";                            
                        this.ndvi_reaf_color_coverage = "-";
                        this.planting_rate_stem_number_average = "-";    
                        this.planting_rate_stem_number_variation = "-";
                        this.planting_rate_stem_number_coverage = "-";
                        this.ndvi_planting_rate_average = "-";
                        this.ndvi_planting_rate_variation = "-";
                        this.ndvi_planting_rate_coverage = "-";                        

                    } else {
                        let detailResult = JSON.parse(result.resultArray[0].analysis_result_data);

                        if (detailResult["analysis-result"][0].average == null || detailResult["analysis-result"][0].average == "") {
                            this.ndvi_reaf_color_average = "-";
                        } else {
                            let ndvi_average = parseFloat(detailResult["analysis-result"][0].average);
                            ndvi_average = Math.round(ndvi_average * 100) / 100;
                            this.ndvi_reaf_color_average = String(ndvi_average);
                        }
                        if (detailResult["analysis-result"][0].variation == null || detailResult["analysis-result"][0].variation == "") {
                            this.ndvi_reaf_color_variation = "-";                            
                        } else {
                            let ndvi_variation = parseFloat(detailResult["analysis-result"][0].variation);
                            ndvi_variation = Math.round(ndvi_variation * 10) / 10;
                            this.ndvi_reaf_color_variation = String(ndvi_variation) + "%";                            
                        }
                        if (detailResult["analysis-result"][0].rate == null || detailResult["analysis-result"][0].rate == "") {
                            this.ndvi_reaf_color_coverage = "-";
                        } else {
                            let ndvi_cover_rate = parseFloat(detailResult["analysis-result"][0].rate);
                            ndvi_cover_rate = Math.round(ndvi_cover_rate * 1000) / 10;
                            this.ndvi_reaf_color_coverage = String(ndvi_cover_rate) + "%";
                        }

                        if (detailResult["analysis-result"][1].average == null || detailResult["analysis-result"][1].average == "") {
                            this.planting_rate_stem_number_average = "-";    
                        } else {
                            let skin_grafting_rate_average = parseFloat(detailResult["analysis-result"][1].average);
                            skin_grafting_rate_average = Math.round(skin_grafting_rate_average * 100) / 100;
                            this.planting_rate_stem_number_average = String(skin_grafting_rate_average);
                        }
                        if (detailResult["analysis-result"][1].variation == null || detailResult["analysis-result"][1].variation == "") {
                            this.planting_rate_stem_number_variation = "-";
                        } else {
                            let skin_grafting_rate_variation = parseFloat(detailResult["analysis-result"][1].variation);
                            skin_grafting_rate_variation = Math.round(skin_grafting_rate_variation * 10) / 10;
                            this.planting_rate_stem_number_variation = String(skin_grafting_rate_variation) + "%";
                        }
                        if (detailResult["analysis-result"][1].rate == null || detailResult["analysis-result"][1].rate == "") {
                            this.planting_rate_stem_number_coverage = "-";
                        } else {
                            let skin_grafting_rate_cover_rate = parseFloat(detailResult["analysis-result"][1].rate);
                            skin_grafting_rate_cover_rate = Math.round(skin_grafting_rate_cover_rate * 1000) / 10;
                            this.planting_rate_stem_number_coverage = String(skin_grafting_rate_cover_rate) + "%";
                        }

                        if (detailResult["analysis-result"][2].average == null || detailResult["analysis-result"][2].average == "") {
                            this.ndvi_planting_rate_average = "-";
                        } else {
                            let ndvix_average = parseFloat(detailResult["analysis-result"][2].average);
                            ndvix_average = Math.round(ndvix_average * 100) / 100;
                            this.ndvi_planting_rate_average = String(ndvix_average);
                        }
                        if (detailResult["analysis-result"][2].variation == null || detailResult["analysis-result"][2].variation == "") {
                            this.ndvi_planting_rate_variation = "-";
                        } else {
                            let ndvix_variation = parseFloat(detailResult["analysis-result"][2].variation);
                            ndvix_variation = Math.round(ndvix_variation * 10) / 10;
                            this.ndvi_planting_rate_variation = String(ndvix_variation) + "%";
                        }
                        if (detailResult["analysis-result"][2].rate == null || detailResult["analysis-result"][2].rate == "") {
                            this.ndvi_planting_rate_coverage = "-";                        
                        } else {
                            let ndvix_cover_rate = parseFloat(detailResult["analysis-result"][2].rate);
                            ndvix_cover_rate = Math.round(ndvix_cover_rate * 1000) / 10;    
                            this.ndvi_planting_rate_coverage = String(ndvix_cover_rate) + "%";                        
                        }

                    }
                    resolve();
                } else {
                    console.log("InspectionComponent------------getFieldHistories() getFieldHistorys nodata erorr-------------");
                    console.log("system error");
                    reject();
                }      
            })
            .catch((err)=> {
                console.log("InspectionComponent------------getFieldHistories() getFieldHistorys err-------------");
                console.log(err);
                let jsonResult = JSON.parse(err.text());
                if (typeof jsonResult.statusCode === "undefined") {
                    console.log("system error ");
                } else {
                    console.log("system error " + jsonResult.statusCode + " : " + jsonResult.messages);
                }
                reject();
            });
        })
    }
}
