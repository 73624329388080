import { Component, OnInit } from '@angular/core';
import {MatDialogRef } from '@angular/material/dialog';
import { CommonConst } from '../../constant/common-const';

@Component({
    selector: 'app-logout-dialog',
    templateUrl: './logout-dialog.component.html',
    styleUrls: ['./logout-dialog.component.css']
})
export class LogoutDialogComponent implements OnInit {
    logout_message: string;

    constructor(
        public dialogRef: MatDialogRef<LogoutDialogComponent>
    ) {
        this.logout_message = CommonConst.LOGOUT_MESSAGE;
    }

    ngOnInit() {
    }

}
