import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CommonConst } from '../../constant/common-const';
import { MessageConst } from '../../constant/message-const';
import { LoginService } from '../../service/login.service';
import { ExecuteResult } from '../../model/execute-result';
import { MessageDialogComponent } from '../../dialog/message-dialog/message-dialog.component';
import { MatDialog} from '@angular/material/dialog';

export interface DialogData {
    dialogMessage: string;
}

@Component({
    selector: 'app-resetpass-dialog',
    templateUrl: './resetpass-dialog.component.html',
    styleUrls: ['./resetpass-dialog.component.css']
})
export class ResetpassDialogComponent implements OnInit {
    password_reset: string;
    message_1: string;
    message_2: string;
    login_id: string;
    send_btn: string;
    messageList: string[];
    loginId:string;
    private sendMailResult:    ExecuteResult;

    constructor(
        public dialogRef: MatDialogRef<ResetpassDialogComponent>,
        public dialogRef2: MatDialogRef<MessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private loginservice: LoginService,
        public dialog: MatDialog
    ) {
        console.log("ResetpassDialogComponent-------------constructor() ------------");
        this.password_reset = CommonConst.PASSWORD_RESET;
        this.message_1 = CommonConst.MESSAGE_1;
        this.message_2 = CommonConst.MESSAGE_2;
        this.login_id = CommonConst.LOGIN_ID;
        this.send_btn = CommonConst.SEND_BTN;
    }

    ngOnInit() {
        console.log("    ResetpassComponent-------------ngOnInit() ------------");
        this.loginId = "";
        this.messageList = [""];
    }

    sendMail() {
        console.log("    ResetpassComponent-------------sendMail() ------------");
        this.messageList = [""];
        // input check
        if(this.loginId == ""){
            this.messageList.push(MessageConst.LOGIN_NO_LOGINID);
            return;
        }
        try {
            // send Email
            this.loginservice.sendPasswordReset(this.loginId)
            .then((result) => {
                this.dialogRef.close();
                this.openMessageDialog();
            }).catch((err) => {
                this.messageList.push(err.additionalMessage.message);
            });
            return;
        } catch(e) {
            console.log("System " + e);
            this.messageList.push(MessageConst.SYSTEM_ERROR_MESSAGE);
            return;
        }
    }

    openMessageDialog():void {
        console.log("    ResetpassComponent-------------openMessageDialog() ------------");
        this.dialogRef2 = this.dialog.open(MessageDialogComponent,{
            data: {dialogMessage: MessageConst.RESET_PASS_SUCCESS}
        });
    }

}
