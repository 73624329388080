import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent }    from './component/login/login.component';
import { DirectListComponent }    from './component/direct-list/direct-list.component';
import { ChangepassComponent }    from './component/changepass/changepass.component';


const routes: Routes = [
  { path:'', redirectTo: '/login', pathMatch: 'full' },

  { path:'',           component:LoginComponent},


  { path:'direct-list', component: DirectListComponent},
  { path:'password', component: ChangepassComponent },

 // { path:'direct-detail/:direct_id', component:InspectionListComponent},
 // { path:'inspection/:field_no', component:InspectionComponent},


  //  { path:'inspection-detail', component:InspectionDetailComponent},
//  { path:'delivery',   component:DeliveryListComponent},
//  { path:'password', component: ChangepassComponent },

  { path: 'login', component: LoginComponent, pathMatch: 'full'},
//  { path: '**', component: LoginComponent}
//{ path: 'login', component: DirectListComponent, pathMatch: 'full'},
{ path: '**', component: DirectListComponent}

];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
