export class CommonConst {

    public static readonly UI_SYSTEM_VERSION          = "Ver.1.0.0";
    public static readonly UI_SYSTEM_INTERNAL_VERSION = "Ver.1.0.0.4";

    public static readonly EMPTY_TEXT_DB             = "NULL";
    public static readonly CROSS_BUTTON              = "CROSS_BTN";
    public static readonly HTTP_STATUS_CODE_OK       = "200";

    public static readonly ANALYSIS_RESULT_LIST = [
        {"key":"1" ,"value":"解析成功"},
        {"key":"0" ,"value":"データなし"},
        {"key":"-1","value":"解析失敗"},
        {"key":"99","value":"エラー"},
        {"key":""  ,"value":"未解析"}
    ];

    public static readonly ANALYSIS_RESULT_SUCCESS = "1";

    public static readonly ERROR_CODE_LIST = [
        {"key":""  ,"value":""},
        {"key":"0"  ,"value":"データなし"},
        {"key":"10" ,"value":"飛行エラー"},
        {"key":"50" ,"value":"天候エラー"},
        {"key":"60" ,"value":"ポリゴンエラー"},
        {"key":"70" ,"value":"低NDVI値エラー(0.30)"},
        {"key":"71" ,"value":"低NDVI値エラー(0.05)"},
        {"key":"99" ,"value":"その他エラー"}
    ];
    public static readonly MODIFY_FLAG_LIST = [
        {"key":"0" ,"value":"-"},
        {"key":"1" ,"value":"マ"},
        {"key":"2" ,"value":"ポ"},
        {"key":"3" ,"value":"マポ"}
    ];
    public static readonly INSPECTION_RESULT_LIST = [
        {"key":"pending" ,"value":"保留"},
        {"key":"nodata"  ,"value":"データなし"},
        {"key":"ok"      ,"value":"納品可"},
        {"key":"ng"      ,"value":"納品不可"}
    ];

    // INSPECTION RESULT
    public static readonly INSPECTION_RESULT_PENDING = "pending";
    public static readonly INSPECTION_RESULT_NODATA  = "nodata";
    public static readonly INSPECTION_RESULT_OK      = "ok";
    public static readonly INSPECTION_RESULT_NG      = "ng";

    public static readonly ERROR_CODE_OTHER_ERROR    = "99";
    
    public static readonly IMAGE_FILE_VISIBLE   = "bmp_rot_";
    public static readonly IMAGE_FILE_NDVI      = "ndvi_rot_";
    public static readonly IMAGE_FILE_VCR       = "vcr_rot_";
    public static readonly IMAGE_FILE_NDVIX     = "nxv_rot_";
    public static readonly IMAGE_FILE_EXTENTION = ".png";
    public static readonly IMAGE_FILE_GUIDE     = "guide_rot_";
    public static readonly IMAGE_FILE_PLOT     = "plot_";
    public static readonly CSV_FILE_STATISTICS     = "statistics_";
    public static readonly CSV_FILE_LOCATION     = "location_";
    public static readonly CSV_FILE_EXTENTION = ".csv";

    // UPDATE FLAG
    public static readonly INSPECTION_UPDATE_FLAG_ANALYSIS_RESULT = "0";
    public static readonly INSPECTION_UPDATE_FLAG_INSPECTION_RESULT = "1";

    public static readonly PRODUCT_KIND_UNKNOWN = "不明";
    public static readonly HOUR_MILLISECOND = 3600000;
    public static readonly WARNING_HOUR_AFTER_RECEPTION = 36;
    public static readonly OK_BTN_CTL_AVERAGE_THRESHOLD_1 = "0";
    public static readonly OK_BTN_CTL_AVERAGE_THRESHOLD_2 = "-1";

    public static readonly LOGIN_ID         = "ログインID";
    public static readonly TITLE            = "検品納品システム 2020";

    //for ChangePass
    public static readonly PASSWORD_SETTING = "パスワード設定";    
    public static readonly CONFIRM_CODE     = "確認キー";
    public static readonly NEW_PASSWORD     = "新しいパスワード";
    public static readonly CONFIRM_PASSWORD = "新しいパスワード(再入力)";
    public static readonly SETTING_BTN      = "設定";

    // for Login
    public static readonly PASSWORD     = "パスワード";
    public static readonly PASSWORDLOST = "パスワードを忘れた場合";
    public static readonly LOGIN_BTN    = "ログイン";

    // for ResetPass
    public static readonly PASSWORD_RESET = "パスワードリセット";    
    public static readonly SEND_BTN       = "送信";
    public static readonly MESSAGE_1      = "パスワードをリセットするログインIDを左のフォームに入力し、送信ボタンを押してください。";
    public static readonly MESSAGE_2      = "送信後、ログインIDに設定されたメールアドレスにパスワード設定メールが通知されますので、メールの案内に従ってパスワードの再設定をしてください。";

    // for LogOut
    public static readonly LOGOUT_MESSAGE = "ログアウトします。";
    public static readonly DISCARD_MESSAGE_1 = "入力内容が変更されています。";
    public static readonly DISCARD_MESSAGE_2 = "保存する場合は「戻る」を選択し、「入力内容を保存」を選択してください。";
    public static readonly DISCARD_MESSAGE_3 = "破棄する場合は「破棄する」を選択してください。（遷移する）";

}
