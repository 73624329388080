import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Inject} from '@angular/core';
import { MatTableDataSource} from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import { MessageDialogComponent } from '../../dialog/message-dialog/message-dialog.component';
import { MatDialogRef} from '@angular/material/dialog';
import { FieldRow } from '../../model/field-row';
import { ContinuousInspection } from '../../model/continuous-inspection';
import { DbdataService } from '../../service/dbdata.service';
import { InspectionComponent } from '../inspection/inspection.component';
import { RunningDialogComponent } from '../../dialog/running-dialog/running-dialog.component';
import { InfomationDialogComponent } from '../../dialog/infomation-dialog/infomation-dialog.component';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
import { ProgressDialogComponent} from '../../dialog/progress-dialog/progress-dialog.component';
import { MessageConst } from '../../constant/message-const';
import { CommonConst } from '../../constant/common-const';

/**
 * <pre>
 *   Field ID list screen component<br>
 * <br>
 *   Update Date         Update User         Contents Of Update<br> 
 *   2019/02/17          S.Sakamoto          Create New<br>
 * </pre>
 */
@Component({
    selector: 'app-field-list',
    templateUrl: './field-list.component.html',
    styleUrls: ['./field-list.component.css']
})
export class FieldListComponent implements OnInit {
    title: string;
    str_field_list_style='field_list_hidden';
    @ViewChild(InspectionComponent,{static: false}) protected inspectionComponent: InspectionComponent;
    @Output() goDirectList = new EventEmitter();
    @Input() selectDirectId: string;
    @Input() usernameFromDrectidList: string;
    @ViewChild(ProgressDialogComponent,{static: false}) protected progressDialogComponent: ProgressDialogComponent;
    receive_direct_id:string;
    dialogRef;
    dialogRef2: MatDialogRef<MessageDialogComponent>;
    dialogRef3: MatDialogRef<InfomationDialogComponent>;
    displayedColumns: string[] = [ 'detail_link', 'field_no', 'field_id', 'shooting_result', 'request_date', 'analysis_result', 'inspection_user_id', 'inspection_result', 'inspection_error_code', 'inspection_other_error_comment', 'modify_flag','delivery_user_id','delivery_error_flag','delivery_date','inspection_biko'];
    field_id_dataSource: MatTableDataSource<FieldRow>;
    deliveryBtnCtl: boolean;

    continuousInspections: ContinuousInspection[];

    direct_id: string;
    contract_kind: string;
    scheduled_date: string;
    prefecture_name: string;
    camera_kind: string;
    latest_request_date: string;
    work_kind: string;
    direct_user_id: string;
    request_number_of_field: string;
    shooting_number_of_field: string;
    analysis_error_number_of_field: string;
    non_inspection_number_of_field: string;
    pending_inspection_number_of_field: string;
    non_delivery_number_of_field: string;
    delivery_number_of_field: string;
    direct_comment: string;
    initial_direct_comment: string;

    deliveryCurrentNo: number;
    deliverytotalNo: number;
    existDeliveryError: boolean;

    constructor(
        public dialog: MatDialog,
        private dbdataService: DbdataService,
        private fieldRow: FieldRow
    ) {
        console.log("FieldListComponent------------constructor() -------------");
        this.title = CommonConst.TITLE;
    }

    ngOnInit() {
        console.log("FieldListComponent------------ngOnInit() -------------");
    }

    comeFromDirectList(selectDirectId: string) {
        console.log("FieldListComponent------------comeFromDirectList() -------------");
        console.log("Receive direct_id = " + selectDirectId )
        this.receive_direct_id = selectDirectId;
        this.str_field_list_style = 'field_list_display';
        this.openRunningDialog();
        this.getFieldListAndDirectDetail();
    }

    goToDirectList() {
        console.log("FieldListComponent------------goToDirectList() -------------");
        if(this.initial_direct_comment != this.direct_comment) {
            this.dialogRef3 = this.dialog.open(InfomationDialogComponent, {disableClose: true});
            this.dialogRef3.afterClosed().subscribe(result => {
                if(result) {
                    this.str_field_list_style='field_list_hidden';
                    this.goDirectList.emit();    
                }
            });   
        } else {
            this.str_field_list_style='field_list_hidden';
            this.goDirectList.emit();    
        }
    }

    goToInspection(field_id: string, data_version: string) {
        console.log("FieldListComponent------------goToInspection() -------------");
        if(this.initial_direct_comment != this.direct_comment) {
            this.dialogRef3 = this.dialog.open(InfomationDialogComponent, {disableClose: true});
            this.dialogRef3.afterClosed().subscribe(result => {
                if(result) {
                    console.log("goToInspection(" + field_id + ")")
                    this.str_field_list_style='field_list_hidden';
                    this.inspectionComponent.comeFromFieldList(this.receive_direct_id, field_id, data_version, this.continuousInspections);
                }
            });   
        } else {
            console.log("goToInspection(" + field_id + ")")
            this.str_field_list_style='field_list_hidden';
            this.inspectionComponent.comeFromFieldList(this.receive_direct_id, field_id, data_version, this.continuousInspections);
        }
    }

    comeFromInspection() {
        console.log("FieldListComponent------------comeFromInspection() -------------");
        this.str_field_list_style = 'field_list_display';
        this.openRunningDialog();
        this.getFieldListAndDirectDetail();
    }

    getFieldListAndDirectDetail() {
        console.log("FieldListComponent------------getFieldListAndDirectDetail() -------------");
        this.initialContents();
        this.dbdataService.getFieldList(this.receive_direct_id)
        .then((result)=> {
            console.log("FieldListComponent------------getFieldListAndDirectDetail() getFieldList result success-------------");
            console.log("result.totalCount = " + result.totalCount);

            if (result.totalCount != "0") {
                this.field_id_dataSource = result.resultDataSorce;
                this.deliverytotalNo = result.totalCount;
            }

            this.dbdataService.getDirectDetail(this.receive_direct_id)
            .then((result)=> {
                console.log("FieldListComponent------------getFieldListAndDirectDetail() getDirectDetail result success-------------");

                if (result.resultArray[0].contract_kind == null || result.resultArray[0].contract_kind == "") {
                    result.resultArray[0].contract_kind = "-";
                }
                if (result.resultArray[0].scheduled_date == null || result.resultArray[0].scheduled_date == "") {
                    result.resultArray[0].scheduled_date = "-";
                } else {
                    var date = new Date(result.resultArray[0].scheduled_date);

                    // change utc to jst
                    date.setHours(date.getHours() + 9);
                    var yyyy = date.getFullYear();

                    var toDoubleDigits = function(num) {
                        num += "";
                        if (num.length === 1) {
                          num = "0" + num;
                        }
                       return num;
                    }

                    var mm = toDoubleDigits(date.getMonth() + 1);
                    var dd = toDoubleDigits(date.getDate());
                    result.resultArray[0].scheduled_date = yyyy + "-" + mm + "-" + dd;
                }
                if (result.resultArray[0].prefecture_name == null || result.resultArray[0].prefecture_name == "") {
                    result.resultArray[0].prefecture_name = "-";
                }
                if (result.resultArray[0].camera_kind == null || result.resultArray[0].camera_kind == "") {
                    result.resultArray[0].camera_kind = "-";
                }
                if (result.resultArray[0].latest_request_date == null || result.resultArray[0].latest_request_date == "") {
                    result.resultArray[0].latest_request_date = "9999-12-31 23:59";
                } else {
                    result.resultArray[0].latest_request_date = result.resultArray[0].latest_request_date.substr(0,16);
                }
                if (result.resultArray[0].work_kind == null || result.resultArray[0].work_kind == "") {
                    result.resultArray[0].work_kind = "-";
                }
                if (result.resultArray[0].direct_user_id == null || result.resultArray[0].direct_user_id == "") {
                    result.resultArray[0].direct_user_id = "-";
                }
                if (result.resultArray[0].request_number_of_field == null || result.resultArray[0].request_number_of_field == "") {
                    result.resultArray[0].request_number_of_field = "0";
                }
                if (result.resultArray[0].shooting_number_of_field == null || result.resultArray[0].shooting_number_of_field == "") {
                    result.resultArray[0].shooting_number_of_field = "0";
                }
                if (result.resultArray[0].analysis_error_number_of_field == null || result.resultArray[0].analysis_error_number_of_field == "") {
                    result.resultArray[0].analysis_error_number_of_field = "0";
                }
                if (result.resultArray[0].non_inspection_number_of_field == null || result.resultArray[0].non_inspection_number_of_field == "") {
                    result.resultArray[0].non_inspection_number_of_field = "0";
                }
                if (result.resultArray[0].pending_inspection_number_of_field == null || result.resultArray[0].pending_inspection_number_of_field == "") {
                    result.resultArray[0].pending_inspection_number_of_field = "0";
                }
                if (result.resultArray[0].non_delivery_number_of_field == null || result.resultArray[0].non_delivery_number_of_field == "") {
                    result.resultArray[0].non_delivery_number_of_field = "0";
                }
                if (result.resultArray[0].delivery_number_of_field == null || result.resultArray[0].delivery_number_of_field == "") {
                    result.resultArray[0].delivery_number_of_field = "0";
                }

                this.contract_kind                      = result.resultArray[0].contract_kind;
                this.scheduled_date                     = result.resultArray[0].scheduled_date;
                this.prefecture_name                    = result.resultArray[0].prefecture_name;
                this.camera_kind                        = result.resultArray[0].camera_kind;
                this.latest_request_date                = result.resultArray[0].latest_request_date;
                this.work_kind                          = result.resultArray[0].work_kind;
                this.direct_user_id                     = result.resultArray[0].direct_user_id;
                this.request_number_of_field            = result.resultArray[0].request_number_of_field;
                this.shooting_number_of_field           = result.resultArray[0].shooting_number_of_field;
                this.analysis_error_number_of_field     = result.resultArray[0].analysis_error_number_of_field;
                this.non_inspection_number_of_field     = result.resultArray[0].non_inspection_number_of_field;
                this.pending_inspection_number_of_field = result.resultArray[0].pending_inspection_number_of_field;
                this.non_delivery_number_of_field       = result.resultArray[0].non_delivery_number_of_field;
                this.delivery_number_of_field           = result.resultArray[0].delivery_number_of_field;
                this.direct_comment                     = result.resultArray[0].direct_comment;
                this.initial_direct_comment             = result.resultArray[0].direct_comment;

                let num_non_inspection_number_of_field = parseInt(this.non_inspection_number_of_field);
                let num_non_delivery_number_of_field   = parseInt(this.non_delivery_number_of_field);
                let num_analysis_error_number_of_field = parseInt(this.analysis_error_number_of_field);
        
                if( num_non_inspection_number_of_field == 0 && //未検品数
                    num_non_delivery_number_of_field > 0 &&    //未納品数
                    num_analysis_error_number_of_field == 0 ){ //解析エラー数
                    this.deliveryBtnCtl = false;
                }
                this.createContinuousInspectionList();
                this.closeRunningDialog();
            })
            .catch((err)=> {
                console.log("FieldListComponent------------getFieldListAndDirectDetail() getDirectDetail error-------------");
                // URL, x_api_key error
                console.log(err.text());
                let jsonResult = JSON.parse(err.text());
                if (typeof jsonResult.statusCode === "undefined") {
                    console.log("system error");
                } else {
                    console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
                }
                this.closeRunningDialog();
                this.openMessageDialog(MessageConst.MODAL_INITIAL_NG); 
            });
        })
        .catch((err)=> {
            console.log("FieldListComponent------------getFieldListAndDirectDetail() getFieldList error-------------");
            // URL, x_api_key error
            console.log(err.text());
            let jsonResult = JSON.parse(err.text());
            if (typeof jsonResult.statusCode === "undefined") {
                console.log("system error");
            } else {
                console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
            }
            this.closeRunningDialog();
            this.openMessageDialog(MessageConst.MODAL_INITIAL_NG); 
        });
    }

    updateDirectComment() {
        console.log("FieldListComponent-------------updateDirectComment() ------------");
        this.openRunningDialog();
        if (this.direct_comment === "undefined" || this.direct_comment == "") {
            this.direct_comment = null
        }

        this.dbdataService.putDirectComment(this.receive_direct_id,this.direct_comment,this.usernameFromDrectidList)
        .then((result)=> {
            console.log("FieldListComponent------------updateDirectComment() result-------------");

            this.getFieldListAndDirectDetail();
        })
        .catch((err)=> {
            console.log("FieldListComponent------------updateDirectComment() error-------------");
            // URL, x_api_key error
            console.log(err.text());
            let jsonResult = JSON.parse(err.text());
            if (typeof jsonResult.statusCode === "undefined") {
                console.log("system error");
            } else {
                console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
            }
            this.closeRunningDialog();
            this.openMessageDialog(MessageConst.MODAL_UPDATE_NG); 
        });
    }

    openRunningDialog(): void {
        console.log("FieldListComponent------------openRunningDialog() -------------");
        this.dialogRef = this.dialog.open(RunningDialogComponent, {disableClose: true});
    }

    closeRunningDialog(): void {
        console.log("FieldListComponent------------closeRunningDialog() -------------");
        this.dialogRef.close('');
    }

    openMessageDialog(message: string): void {
        console.log("FieldListComponent-------------openMessageDialog() ------------");
        this.dialogRef2 = this.dialog.open(MessageDialogComponent,{
            data: {dialogMessage: message},
            disableClose: true
        });
    }

    openConfirmDialog(): void {
        console.log("FieldListComponent-------------openConfirmDialog() ------------");
        this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {dialogMessage: MessageConst.MODAL_DELIVERY_CONFIRM},
            disableClose: true
        });
        this.dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if(result == true) {
                this.openProgressDialog();
            }
        });
    }

    openProgressDialog(): void {
        console.log("FieldListComponent-------------openProgressDialog() ------------");
        this.deliveryCurrentNo = 0;

        this.dialogRef = this.dialog.open(ProgressDialogComponent,{
                data: {
                    dialogMessage: MessageConst.MODAL_DELIVERY_PROGRESS,
                    currentNo: this.deliveryCurrentNo,
                    totalNo: this.deliverytotalNo,
                    btnCtl: false,
                    message_style: "message"

                },
                disableClose: true
        });

        this.delivery();

        this.dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.openRunningDialog();
                this.getFieldListAndDirectDetail();    
            }
        });   

    }

    initialContents() {
        console.log("FieldListComponent-------------initialContents() ------------");

        let emptyElementData: FieldRow[] = [];
        this.field_id_dataSource = new MatTableDataSource<FieldRow>(emptyElementData);

        this.deliveryBtnCtl = true;

        this.contract_kind                      = "";
        this.scheduled_date                     = "";
        this.prefecture_name                    = "";
        this.camera_kind                        = "";
        this.latest_request_date                = "";
        this.work_kind                          = "";
        this.direct_user_id                     = "";
        this.request_number_of_field            = "";
        this.shooting_number_of_field           = "";
        this.analysis_error_number_of_field     = "";
        this.non_inspection_number_of_field     = "";
        this.pending_inspection_number_of_field = "";
        this.non_delivery_number_of_field       = "";
        this.delivery_number_of_field           = "";
        this.direct_comment                     = "";
        this.initial_direct_comment             = "";

    }

    createContinuousInspectionList() {
        console.log("FieldListComponent-------------createContinuousInspectionList() ------------");

        this.continuousInspections = [];
        this.continuousInspections.shift;
        let serial_no: number = 1;

        for (let i in this.field_id_dataSource.data) {

            if (this.field_id_dataSource.data[i].analysis_result == CommonConst.ANALYSIS_RESULT_SUCCESS) {
                let non_inspection_flag = false;

                if (this.field_id_dataSource.data[i].inspection_date == null){
                    non_inspection_flag = true;
                }

                let continuousInspection: ContinuousInspection = {
                    serial_no: serial_no,
                    direct_id: this.field_id_dataSource.data[i].direct_id,
                    field_id: this.field_id_dataSource.data[i].field_id,
                    non_inspection_flag: non_inspection_flag
                };

                this.continuousInspections.push(continuousInspection);
                serial_no++;
            }
        }
    }

    delivery() {
        console.log("FieldListComponent-------------delivery() ------------");
        this.existDeliveryError = false;
        // delivery check
        this.dbdataService.getDirectDetail(this.receive_direct_id)
        .then((result)=> {
            console.log("FieldListComponent------------delivery() getDirectDetail result success-------------");

            let num_non_inspection_number_of_field = parseInt(result.resultArray[0].non_inspection_number_of_field);
            let num_non_delivery_number_of_field   = parseInt(result.resultArray[0].non_delivery_number_of_field);
            let num_analysis_error_number_of_field = parseInt(result.resultArray[0].analysis_error_number_of_field);
    
            if( num_non_inspection_number_of_field == 0 && 
                num_non_delivery_number_of_field > 0 &&    
                num_analysis_error_number_of_field == 0 ){ 

                // get field list
                this.dbdataService.getFieldList(this.receive_direct_id)
                .then((result)=> {
                    console.log("FieldListComponent------------delivery() getFieldList result success-------------");
                    console.log("result.totalCount = " + result.totalCount);
        
                    if (result.totalCount != "0") {
                        this.field_id_dataSource = result.resultDataSorce;
                        this.deliverytotalNo = result.totalCount;

                        // progress screen setting
                        this.dialogRef.componentInstance.data.totalNo = this.deliverytotalNo;

                        // field roop 
                        console.log("Delivery Fields LOOP Start ");    
                        this.deliveryLoop( 0, this.deliverytotalNo - 1, this.field_id_dataSource.data);
    
                    } else {
                        console.log(" system error field_tbl no data ");
                        this.dialogRef.close();
                        this.openMessageDialog(MessageConst.MODAL_DELIVERY_NG); 
                        return;
                    }
                })
                .catch((err)=> {
                    console.log("FieldListComponent------------delivery() getFieldList error-------------");
                    // URL, x_api_key error
                    console.log(err.text());
                    let jsonResult = JSON.parse(err.text());
                    if (typeof jsonResult.statusCode === "undefined") {
                        console.log("system error");
                    } else {
                        console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
                    }
                    this.dialogRef.close();
                    this.openMessageDialog(MessageConst.MODAL_DELIVERY_NG); 
                });
            } else {
                console.log("FieldListComponent------------delivery() delivery check error -------------");
                this.deliveryBtnCtl = false;
                this.dialogRef.close();
                this.openMessageDialog(MessageConst.MODAL_DELIVERY_CHECK_NG);
                return; 
            }
        })
        .catch((err)=> {
            console.log("FieldListComponent------------delivery() getDirectDetail error-------------");
            // URL, x_api_key error
            console.log(err.text());
            let jsonResult = JSON.parse(err.text());
            if (typeof jsonResult.statusCode === "undefined") {
                console.log("system error");
            } else {
                console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
            }
            this.dialogRef.close();
            this.openMessageDialog(MessageConst.MODAL_DELIVERY_NG); 
        });
    }

    deliveryLoop( i: any, end: any, fieldList: any) :Promise<any> {
        console.log("FieldListComponent-----delivery() deliveryLoop() ----- " + fieldList[i].field_id);

        // progress screen setting
        this.deliveryCurrentNo++;
        this.dialogRef.componentInstance.data.currentNo = this.deliveryCurrentNo;

        // Redelivery check
        return this.dbdataService.getFieldHistorys(fieldList[i].direct_id, fieldList[i].field_id, "")
        .then((result)=> {
            console.log("FieldListComponent----deliveryLoop----getFieldHistorys() result success -------------");

            console.log("result.resultArray.length------  " + result.resultArray.length);
            if (result.resultArray.length !== 0) {

                for (let j in result.resultArray) {
                    console.log("j = " + j);
                    console.log("---data_version = " + result.resultArray[j].data_version + " inspection_result = " + result.resultArray[j].inspection_result);
                    if (result.resultArray[j].inspection_result == CommonConst.INSPECTION_RESULT_OK 
                        && result.resultArray[j].delivery_date  != CommonConst.EMPTY_TEXT_DB) {

                        // target field delyvery skip
                        if ( i < end ) {
                            console.log("skip this field Delivery");
                            return this.deliveryLoop( i+1, end, fieldList);
                        }
                        else {
                            console.log("Delivery Fields LOOP End after field_history_check");
                            this.deliveryFinal();
                            return Promise.resolve('end');
                        } 
                    }
                }    

                console.log("not skip this field Delivery");
                console.log("FieldListComponent--------deliveryLoop()----create inspection_result_data-------------");
                console.log("analysis_result_data = " + result.resultArray[result.resultArray.length - fieldList[i].data_version].analysis_result_data);

                let inspection_result_data;

                try {
                    // get analysis_result_data
                    let analysis_result_data = JSON.parse(result.resultArray[result.resultArray.length - fieldList[i].data_version].analysis_result_data);

                    let result_flg = "0";
                    let inspection_error_code = null;
                    let remarks = fieldList[i].inspection_other_error_comment;
                    if (remarks == "-") {
                        remarks = null;
                    }

                    if (fieldList[i].inspection_result == CommonConst.INSPECTION_RESULT_OK ) {
                        result_flg = "1";
                        inspection_error_code = fieldList[i].inspection_error_code;
                    } else if (fieldList[i].inspection_result == CommonConst.INSPECTION_RESULT_NG) {
                        inspection_error_code = fieldList[i].inspection_error_code;
                    } else if (fieldList[i].inspection_result == CommonConst.INSPECTION_RESULT_NODATA) {
                        inspection_error_code = "0";
                    }
                    analysis_result_data["analysis-result"][0]["result-flg"] = result_flg;
                    analysis_result_data["analysis-result"][1]["result-flg"] = result_flg;
                    analysis_result_data["analysis-result"][2]["result-flg"] = result_flg;
                    analysis_result_data["analysis-result"][0]["analysis-error-code"] = inspection_error_code;
                    analysis_result_data["analysis-result"][1]["analysis-error-code"] = inspection_error_code;
                    analysis_result_data["analysis-result"][2]["analysis-error-code"] = inspection_error_code;
                    analysis_result_data["analysis-result"][0]["remarks"] = remarks;
                    analysis_result_data["analysis-result"][1]["remarks"] = remarks;
                    analysis_result_data["analysis-result"][2]["remarks"] = remarks;

                    inspection_result_data = JSON.stringify(analysis_result_data);

                } catch(err){
                    console.log("FieldListComponent--------deliveryLoop()----create inspection_result_data---err---");
                    console.log(err);
                    console.log("system error");
                    this.dialogRef.close();
                    this.openMessageDialog(MessageConst.MODAL_DELIVERY_NG);
                    return;
                }

                this.dbdataService.executeDelivery(this.receive_direct_id, 
                                                    fieldList[i].field_id,
                                                    fieldList[i].data_version,
                                                    inspection_result_data,
                                                    this.usernameFromDrectidList
                                                    )
                .then((result)=> {
                    console.log("FieldListComponent--------deliveryLoop()----executeDelivery() result-------------");
                    console.log("result.result = " + result.result);
                    console.log("result.message = " + result.message);
                    
                    let delivery_error_flag_field = null;
                    let delivery_error_flag_field_history = "NULL";
                    if (!result.result) {
                        delivery_error_flag_field = "エラー";
                        delivery_error_flag_field_history = "エラー";
                        this.existDeliveryError = true;
                    }

                    // update field_tbl
                    this.dbdataService.putFieldDelivery(this.receive_direct_id, fieldList[i].field_id, delivery_error_flag_field, this.usernameFromDrectidList)
                    .then((result)=> {
                        console.log("FieldListComponent--------deliveryLoop()----putFieldDelivery() result-------------");
                        console.log("result.result = " + result.result);
                        console.log("result.message = " + result.message);
    
                        this.dbdataService.putFieldHistoryDelivery(this.receive_direct_id, fieldList[i].field_id, fieldList[i].data_version, delivery_error_flag_field_history, this.usernameFromDrectidList )
                        .then((result)=> {
                            console.log("FieldListComponent--------deliveryLoop()----putFieldHistoryDelivery() result-------------");
                            console.log("result.result = " + result.result);
                            console.log("result.message = " + result.message);
        
                            if ( i < end ) {
                                console.log("next Delivery");
                                return this.deliveryLoop( i+1, end, fieldList);
                            }
                            else {
                                console.log("Delivery Fields LOOP End ");
                                this.deliveryFinal();
                                return Promise.resolve('end');
                            } 
        
                        })
                        .catch((err)=> {
                            console.log("FieldListComponent--------deliveryLoop()----putFieldHistoryDelivery() error-------------");
                            // URL, x_api_key error
                            console.log(err.text());
                            let jsonResult = JSON.parse(err.text());
                            if (typeof jsonResult.statusCode === "undefined") {
                                console.log("system error");
                            } else {
                                console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
                            }
                            this.dialogRef.close();
                            this.openMessageDialog(MessageConst.MODAL_DELIVERY_NG);
                        });
                    })
                    .catch((err)=> {
                        console.log("FieldListComponent--------deliveryLoop()----putFieldDelivery() error-------------");
                        // URL, x_api_key error
                        console.log(err.text());
                        let jsonResult = JSON.parse(err.text());
                        if (typeof jsonResult.statusCode === "undefined") {
                            console.log("system error");
                        } else {
                            console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
                        }
                        this.dialogRef.close();
                        this.openMessageDialog(MessageConst.MODAL_DELIVERY_NG);
                    });
                })
                .catch((err)=> {
                    console.log("FieldListComponent--------deliveryLoop()----executeDelivery() error-------------");
                    // URL, x_api_key error
                    console.log("system error");
                    this.dialogRef.close();
                    this.openMessageDialog(MessageConst.MODAL_DELIVERY_NG);
                });

            } else {
                console.log("FieldListComponent------deliveryLoop()------getFieldHistorys() nodata erorr-------------");
                console.log("system error");
                this.dialogRef.close();
                this.openMessageDialog(MessageConst.MODAL_DELIVERY_NG);
                return;        
            }          

        })
        .catch((err)=> {
            console.log("FieldListComponent------------getFieldHistorys() err-------------");
            console.log(err);
            let jsonResult = JSON.parse(err.text());
            if (typeof jsonResult.statusCode === "undefined") {
                console.log("system error ");
            } else {
                console.log("system error " + jsonResult.statusCode + " : " + jsonResult.messages);
            }
            this.dialogRef.close();
            this.openMessageDialog(MessageConst.MODAL_DELIVERY_NG); 
        });
    }

    deliveryFinal(){
        console.log("FieldListComponent------------deliveryFinal()-------------");
        // Recalculation
        this.dbdataService.putDirectCalc(this.receive_direct_id,this.usernameFromDrectidList)
        .then((result)=> {
            console.log("FieldListComponent--------deliveryFinal()----putDirectCalc() result-------------");

            this.dbdataService.putDirectComment(this.receive_direct_id,this.direct_comment,this.usernameFromDrectidList)
            .then((result)=> {
                console.log("FieldListComponent--------deliveryFinal()----putDirectComment() result-------------");
                if (this.direct_comment === "undefined" || this.direct_comment == "") {
                    this.direct_comment = null
                }
                // update direct comment
                this.dbdataService.putDirectComment(this.receive_direct_id,this.direct_comment,this.usernameFromDrectidList)
                .then((result)=> {
                    console.log("FieldListComponent------------deliveryFinal() putDirectComment result-------------");

                    // progress dialog 
                    if(this.existDeliveryError) {
                        this.dialogRef.componentInstance.data.message_style = "alertmessage";
                        this.dialogRef.componentInstance.data.dialogMessage = MessageConst.MODAL_DELIVERY_FARM_NG;
                        
                    } else {
                        this.dialogRef.componentInstance.data.message_style = "message";
                        this.dialogRef.componentInstance.data.dialogMessage = MessageConst.MODAL_DELIVERY_OK;
                    }
                    this.dialogRef.componentInstance.data.btnCtl = true;
        
                })
                .catch((err)=> {
                    console.log("FieldListComponent------------deliveryFinal() putDirectComment error-------------");
                    // URL, x_api_key error
                    console.log(err.text());
                    let jsonResult = JSON.parse(err.text());
                    if (typeof jsonResult.statusCode === "undefined") {
                        console.log("system error");
                    } else {
                        console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
                    }
                    this.closeRunningDialog();
                    this.openMessageDialog(MessageConst.MODAL_DELIVERY_NG); 
                });

            })
            .catch((err)=> {
                console.log("FieldListComponent--------deliveryFinal()----putDirectComment() error-------------");
                // URL, x_api_key error
                console.log(err.text());
                let jsonResult = JSON.parse(err.text());
                if (typeof jsonResult.statusCode === "undefined") {
                    console.log("system error");
                } else {
                    console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
                }
                this.closeRunningDialog();
                this.openMessageDialog(MessageConst.MODAL_DELIVERY_NG); 
            });

        })
        .catch((err)=> {
            console.log("FieldListComponent--------deliveryFinal()----putDirectCalc() error-------------");
            // URL, x_api_key error
            console.log(err);
            let jsonResult = JSON.parse(err.text());
            if (typeof jsonResult.statusCode === "undefined") {
                console.log("system error");
            } else {
                console.log("system error" + jsonResult.statusCode + " : " + jsonResult.messages);
            }
            this.dialogRef.close();
            this.openMessageDialog(MessageConst.MODAL_DELIVERY_NG); 
        });
    }
}
